import React, { useState } from 'react';

import { Tooltip } from 'antd';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectionsIcon from '@material-ui/icons/Directions';
import MapIcon from '@material-ui/icons/Map';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { deleteListFromBoard, setSelectedList } from '../../store/actions';
import { ListOptions } from '../../styled/Boards';
import { DroppableCards, ListStyle } from '../../styled/list_styled';
import ActivityCard from './activity/ActivityCard';
import ListButton from './ListButton';
import ListHeader from './ListHeader';
import { addOneDay, isToday } from '../../../../shared/utils/utills';

const List = ({ board, cards, list, listId, index }) => {
    const [activityCount, setActivityCount] = useState(1);

    const dispatch = useDispatch();

    function handleDeleteList() {
        dispatch(deleteListFromBoard(listId));
    }

    function openMap() {
        if (window.confirm('Open all in Google maps?')) {
            var location = '';
            list.cards.forEach((activity) => {
                if (activity?.location?.address) {
                    location += '/' + activity.location.address;
                }
            });
            window.open('https://www.google.com/maps/dir/' + location);
        }
    }

    return (
        <Draggable
            draggableId={String(listId)}
            index={index}
            // isDragDisabled={index === 0}
        >
            {(provided) => (
                <ListStyle
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    theme={
                        isToday(addOneDay(board.startDate, index))
                            ? '8px solid #7093ad'
                            : '3px solid darkgrey'
                    }
                >
                    <ListHeader
                        board={board}
                        index={index}
                        listId={list.id}
                        cards={list.cards}
                        list={list}
                    />
                    <Droppable droppableId={String(listId)}>
                        {(provided) => (
                            <DroppableCards
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {cards != null
                                    ? cards.map((card, index) => (
                                          <ActivityCard
                                              index={index}
                                              key={card.id}
                                              card={card}
                                              boardId={board.id}
                                              listId={listId}
                                              activityCount={activityCount}
                                              targetLocation={
                                                  list.targetLocation
                                              }
                                              setActivityCount={
                                                  setActivityCount
                                              }
                                          />
                                      ))
                                    : null}
                                {provided.placeholder}
                            </DroppableCards>
                        )}
                    </Droppable>
                    <ListButton
                        targetLocation={list.targetLocation}
                        listId={listId}
                    />
                    <ListOptions>
                        {index !== 0 && (
                            <Tooltip title="Delete day" placement="bottom">
                                <IconButton
                                    size={'small'}
                                    aria-label="delete"
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                'Are you sure you wish to delete this item?',
                                            )
                                        ) {
                                            handleDeleteList();
                                        }
                                    }}
                                >
                                    <DeleteIcon fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip
                            title="Open your whole itinerary on the google map"
                            placement="bottom"
                            style={{ float: 'right' }}
                        >
                            <IconButton
                                size={'small'}
                                aria-label="map"
                                onClick={() => {
                                    openMap();
                                }}
                            >
                                <DirectionsIcon fontSize={'small'} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Plot your activities on the map below"
                            placement="bottom"
                        >
                            <IconButton
                                size={'small'}
                                aria-label="map"
                                onClick={() => {
                                    dispatch(setSelectedList(list, index + 1));
                                }}
                            >
                                <MapIcon fontSize={'small'} />
                            </IconButton>
                        </Tooltip>
                    </ListOptions>
                </ListStyle>
            )}
        </Draggable>
    );
};

export default List;
