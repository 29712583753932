import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import UnauthModal from '../registration/auth/UnauthModal';

export default function LocalDevelopmentRoute({
    component: Component,
    prevLocation,
    ...rest
}) {
    const { authenticated } = useSelector((state) => state.auth);
    const [showRoute, setShowRoute] = useState(false);

    useEffect(() => {
        setShowRoute(authenticated && process.env.NODE_ENV === 'development');
    }, [authenticated]);

    return (
        <Route
            {...rest}
            render={(props) =>
                showRoute ? (
                    <Component {...props} />
                ) : (
                    <UnauthModal {...props} />
                )
            }
        />
    );
}
