import { toast } from 'react-toastify';

import {
    asyncActionError,
    asyncActionFinish,
    asyncActionStart,
} from '../../../shared/common/async/store/asyncActions';
import { delay } from '../../../shared/utils/utills';

export const INCREMENT_COUNTER = 'INCREMENT_COUNTER';
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER';

export function increment(amount) {
    return async function (dispatch) {
        dispatch(asyncActionStart());

        try {
            await delay(1000);
            dispatch({ type: INCREMENT_COUNTER, payload: amount });
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError(error));
        }
    };
}

export function decrement(amount) {
    return async function (dispatch) {
        dispatch(asyncActionStart());

        try {
            await delay(1000);
            dispatch({ type: DECREMENT_COUNTER, payload: amount });
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError(error));
            toast.error(error);
        }
    };
}
