import React from 'react';

import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledBoardLink = styled.div`
    position: relative;
    display: inline-block;
    width: 15%;
    height: 80px;
    background-color: ${(props) => props.color};
    color: white;
    margin: 0.5%;
    border-radius: 0.3rem;
    transition: all 0.3s;
    background-image: ${(props) => 'url(' + props.theme + ')'};
    background-size: cover;
    background-position: 50%;
    bottom: 0;

    &:hover {
        background-color: #0e566c;
        opacity: 0.75;
    }
    @media only screen and (max-width: 400px) and (min-width: 82px) {
        width: 96%;
        margin: 2%;
    }
    @media only screen and (max-width: 720px) and (min-width: 400px) {
        width: 48%;
    }
    @media only screen and (max-width: 1024px) and (min-width: 720px) {
        width: 30%;
    }
    @media only screen and (max-width: 1480px) and (min-width: 1024px) {
        width: 20%;
    }
`;

const StyledBoardBack = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
    padding: 4px;
    border-radius: 0.3rem;
`;

const StyledAddItinerary = styled(StyledBoardLink)`
    color: #6b808c;
    cursor: pointer;
    position: relative;
`;

const StyledTitle = styled.div`
    text-align: center;
    top: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    font-weight: bold;
    text-shadow: 1px 1px darkgray;
`;

export const ItineraryCard = ({ title, theme }) => (
    <StyledBoardLink color="#659dbd" theme={theme}>
        <StyledBoardBack>
            <StyledTitle>{title}</StyledTitle>
        </StyledBoardBack>
    </StyledBoardLink>
);

export const AddItinerary = ({ onClick, content }) => (
    <StyledAddItinerary color="#eee" onClick={onClick}>
        <StyledTitle>{content}</StyledTitle>
    </StyledAddItinerary>
);

export const ListOptions = styled.div`
    border-radius: 0.3rem;
    padding: 0.4rem 0.4rem 0.4rem 0.6rem;
    color: #172b4d;
    float: right;
`;

export const BackButton = styled.div`
    display: inline-block;
    border-radius: 0.3rem;
    padding: 0.3rem;
    color: #172b4d;
    opacity: 0.7;
    float: right;
    align-self: center;
    cursor: pointer;

    &:hover {
        background-color: #e1e2e6;
    }
`;

export const StyledIcon = styled(Icon)`
    &:hover {
        background-color: #e1e2e6;
        transition: 0.3s;
    }
    cursor: pointer;
`;
