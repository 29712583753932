/* global google */
import React from 'react';

import { Tooltip } from 'antd';

import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Map';

import CustomPlaceInput from '../../../../../shared/common/formFields/CustomPlaceInput';

export default function ActivityLocationForm(values, openMap, targetLocation) {
    return (
        <>
            <label>Activity Location</label>
            {values.location.address && (
                <Tooltip title="View in Google Maps" placement="bottom">
                    <IconButton
                        size={'small'}
                        aria-label="map"
                        style={{ color: 'teal' }}
                        onClick={() => {
                            openMap(values.location.address);
                        }}
                    >
                        <MapIcon fontSize={'small'} />
                    </IconButton>
                </Tooltip>
            )}
            {targetLocation ? (
                <CustomPlaceInput
                    name="location"
                    options={{
                        location: new google.maps.LatLng(targetLocation.latLng),
                        radius: 300,
                    }}
                    placeholder="1234 tokyo, Japan"
                />
            ) : (
                <CustomPlaceInput
                    name="location"
                    placeholder="1234 tokyo, Japan"
                />
            )}
        </>
    );
}
