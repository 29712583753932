/* global google */
import React from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';

import CustomTextArea from '../../../../shared/common/formFields/CustomTextArea';
import CustomTextInput from '../../../../shared/common/formFields/CustomTextInput';
import RevCategoriesForm from '../form/RevCategoriesForm';
import RevLocationForm from '../form/RevLocationForm';
import { loadUserBucketList, updateRevs } from '../service/bucketService';
import { updateRevCard } from '../store/bucketActions';

export default function RevDetailedFormView({ activity, open, setOpen }) {
    const dispatch = useDispatch();

    const initialFormValue = activity ?? {
        title: '',
        location: {
            address: '',
            latLng: null,
        },
        description: '',
        category: [],
        theme: '',
    };

    function openMap(location) {
        if (window.confirm('Open in Google maps?')) {
            window.open('https://maps.google.com?q=' + location);
        }
    }

    const handleSubmit = async (values, setSubmitting) => {
        // console.log(values);
        try {
            await updateRevs(values.id, values);
            dispatch(loadUserBucketList());
            // dispatch(updateRevCard(values));
            setSubmitting(false);
        } catch (error) {
            toast.error(error.message);
            setSubmitting(false);
        } finally {
            setOpen(false);
        }
    };

    return (
        <Modal open={open} size="small" onClose={() => setOpen(false)}>
            <Modal.Content>
                <Formik
                    initialValues={initialFormValue}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values, setSubmitting);
                    }}
                >
                    {({
                        isSubmitting,
                        isValid,
                        dirty,
                        setFieldValue,
                        values,
                    }) => (
                        <Form className="ui form">
                            <label>Activity Name</label>
                            <CustomTextInput
                                name="title"
                                placeholder="Check in at Disneyland*"
                                autoComplete="off"
                            />
                            {RevLocationForm()}
                            <label>Category</label>
                            {RevCategoriesForm(values, setFieldValue)}
                            <br />
                            <label>Additional description</label>
                            <CustomTextArea
                                name="description"
                                placeholder="You can add any additional information here"
                                autoComplete="off"
                            />
                            <Button
                                loading={isSubmitting}
                                disabled={!isValid || !dirty || isSubmitting}
                                type="submit"
                                fluid
                                size="small"
                                color="teal"
                                content="Update"
                            />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
        </Modal>
    );
}
