import React, { useEffect, useState } from 'react';

import { Tooltip } from 'antd';
// import Collapsible from 'react-collapsible';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import CreateItineraryModal from './CreateItineraryModal';
import { getUser } from './service/user';
import {
    deleteUserFromBoard,
    loadUserBoards,
    resetBoard,
    resetTheme,
} from './store/actions';
import { AddItinerary, ItineraryCard } from './styled/Boards';
import {
    CardOptions,
    Content,
    Tile,
    TileText,
} from './styled/itineraries_styled';

export default function Itineraries() {
    const dispatch = useDispatch();
    const { boards } = useSelector((state) => state.boards);

    const [open, setOpen] = useState(false);
    const [user, setUser] = useState('');
    const [loadingInitial, setLoadingInitial] = useState(false);

    useEffect(() => {
        setLoadingInitial(true);
        dispatch(loadUserBoards());
        setLoadingInitial(false);
        dispatch(resetTheme());
        dispatch(resetBoard());
    }, [dispatch]);

    useEffect(() => {
        const user = getUser();
        setUser(user.uid);
    }, []);

    function handleDeleteList(boardId) {
        dispatch(deleteUserFromBoard(boardId, user));
        dispatch(loadUserBoards());
    }

    return (
        <>
            <Header content="Upcoming trips" />
            {loadingInitial && <Loader />}

            {boards.length > 0 &&
                boards
                    .filter((board) => !board.archived)
                    .map((board, index) => (
                        <Link key={index} to={`itineraries/${board.key}`}>
                            <ItineraryCard
                                title={board.title}
                                theme={board.theme}
                            />
                        </Link>
                    ))}

            <AddItinerary
                content="Create new Itinerary"
                onClick={() => {
                    setOpen(true);
                }}
            />
            <CreateItineraryModal open={open} setOpen={setOpen} />

            <Header content="Favorite trips" />
            {boards.length > 0 &&
                boards
                    .filter((board) => board.uid === user)
                    .filter((board) => board.favorited)
                    .map((board, index) => (
                        <Link key={index} to={`itineraries/${board.key}`}>
                            <ItineraryCard
                                title={board.title}
                                theme={board.theme}
                            />
                        </Link>
                    ))}

            <Header content="Invited trips" />
            {boards.length > 0 &&
                boards
                    .filter((board) => board.uid !== user)
                    .map((board, index) => (
                        <Tile key={index} theme={board.theme}>
                            <Content as={Link} to={`itineraries/${board.key}`}>
                                <TileText>{board.title}</TileText>
                            </Content>
                            <CardOptions>
                                <Tooltip
                                    title="Delete the invited itinerary"
                                    placement="bottom"
                                    style={{ float: 'right' }}
                                >
                                    <IconButton
                                        size={'small'}
                                        aria-label="delete"
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    'Are you sure you wish to remove this itinerary? You can always ask the host to invite you again',
                                                )
                                            ) {
                                                handleDeleteList(board.key);
                                            }
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: '5%',
                                            left: '85%',
                                        }}
                                    >
                                        <DeleteIcon
                                            fontSize={'small'}
                                            color={'secondary'}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </CardOptions>
                        </Tile>
                    ))}

            {/*<Collapsible trigger="Archived trips" className="ui header" triggerOpenedClassName="ui header last-child">*/}

            {/*</Collapsible>*/}
            <Header content="Archived trips" />
            {boards.length > 0 &&
                boards
                    .filter((board) => board.uid === user)
                    .filter((board) => board.archived)
                    .map((board, index) => (
                        <Link key={index} to={`itineraries/${board.key}`}>
                            <ItineraryCard
                                title={board.title}
                                theme={board.theme}
                            />
                        </Link>
                    ))}
        </>
    );
}
