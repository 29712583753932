import firebase from '../../../core/config/firebase';

export function calculateTotalCost(board) {
    var totalCost = 0;

    board.lists.map((list) => {
        if (list.cards) {
            list.cards.map((card) => {
                if (card.cost) {
                    totalCost += card.cost;
                }
            });
        }
    });

    return totalCost;
}

export function paidActivies(board) {
    var paidActivies = [];

    board.lists.map((list) => {
        if (list.cards) {
            list.cards.map((card) => {
                if (card.cost) {
                    paidActivies = [...paidActivies, card];
                }
            });
        }
    });

    return paidActivies;
}

export function calculateCostPerPerson(board, totalCostToAttendeeMap) {
    board.lists.forEach((day) => {
        if (day.cards) {
            day.cards.forEach((activity) => {
                if (activity.attended) {
                    Object.values(activity.attended).forEach((attendeeId) => {
                        if (activity.cost) {
                            totalCostToAttendeeMap.set(
                                attendeeId,
                                totalCostToAttendeeMap.get(attendeeId) +
                                    activity.cost / activity.attended.length,
                            );
                        }
                    });
                } else {
                    Object.keys(board.attendees).forEach((attendeeId) => {
                        if (activity.cost) {
                            totalCostToAttendeeMap.set(
                                attendeeId,
                                totalCostToAttendeeMap.get(attendeeId) +
                                    activity.cost / totalCostToAttendeeMap.size,
                            );
                        }
                    });
                }
            });
        }
    });
}
