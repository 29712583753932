import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Image } from 'semantic-ui-react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuIcon from '@material-ui/icons/Menu';

import { signOutFirebase } from '../../firestore/firebaseService';
import { SettingButton, SettingItem } from './styled';

export default function SignedInMenu() {
    const { currentUserProfile } = useSelector((state) => state.profile);
    const history = useHistory();

    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    async function handleSignOut() {
        try {
            history.push('/');
            await signOutFirebase();
        } catch (error) {
            toast.error(error.message);
        }
    }

    function handleAccount() {
        history.push('/account');
        setOpen(false);
    }

    function handleProfile() {
        history.push(`/profile/${currentUserProfile?.id}`);
        setOpen(false);
    }

    return (
        <div className="right menu">
            <SettingButton
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Image
                    avatar
                    spaced="right"
                    src={currentUserProfile?.photoURL || '/assets/user.png'}
                />
                <MenuIcon
                    color={'primary'}
                    fontSize={'small'}
                    style={{ color: 'white' }}
                />
            </SettingButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <SettingItem onClick={handleProfile}>
                                        Profile
                                    </SettingItem>
                                    <SettingItem onClick={handleAccount}>
                                        My account
                                    </SettingItem>
                                    <SettingItem onClick={handleSignOut}>
                                        Logout
                                    </SettingItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
