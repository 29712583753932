import React, { useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DirectionsIcon from '@material-ui/icons/Directions';
import MapIcon from '@material-ui/icons/Map';

import TestMap from '../../sandbox/TestMap';
import {
    addListToBoard,
    dragAndDrop,
    listenBoard,
    setSelectedList,
} from '../store/actions';
import {
    BoardStyle,
    Button,
    ListsContainer,
    NavContainer,
    Title,
    ListOptions,
} from '../styled/itinerary_styled';
import BoardNav from './header/ItineraryMenu';
import List from './list/List';
import ItineraryMap from './map/ItineraryMap';

function Itinerary({ match, board }) {
    const dispatch = useDispatch();
    const { selectedList } = useSelector((state) => state.board);

    const [loading, setLoading] = useState(true);
    const [theme, setTheme] = useState(null);
    const [show, setShow] = useState(true);

    useEffect(() => {
        const boardKey = match.params.id;

        setLoading(true);
        // dispatch(loadBoard(boardKey));
        dispatch(listenBoard(boardKey));

        if (board.theme) {
            setTheme(board.theme);
        }

        setLoading(false);

        return () => setTheme(null);
    }, [dispatch, board.theme, match.params.id]);

    // set the background of the itinerary to the theme picture.
    useEffect(() => {
        document.body.style.backgroundImage = 'url(' + theme + ')';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundColor = '';

        return () => {
            document.body.style.backgroundImage = '';
            document.body.style.backgroundColor = '#eaeaea';
        };
    }, [theme]);

    function onDragEnd(result) {
        const { destination, source, type } = result;
        if (!destination) {
            return;
        }

        dispatch(
            dragAndDrop(
                source.droppableId,
                destination.droppableId,
                source.index,
                destination.index,
                type,
            ),
        );
    }

    function handleAddList() {
        dispatch(addListToBoard());
    }

    if (loading) {
        return <Loader />;
    }

    function handleAllMap() {
        let cards = [];

        board.lists.map((list) => {
            if (list.cards) {
                list.cards.map((card) => {
                    cards.push(card);
                });
            }
        });

        dispatch(setSelectedList({ cards }, 0));
    }

    function openDirectionMap() {
        if (window.confirm('Direct all in Google maps?')) {
            var location = '';
            selectedList.cards.forEach((activity) => {
                if (activity?.location?.address) {
                    location += '/' + activity.location.address;
                }
            });
            window.open('https://www.google.com/maps/dir/' + location);
        }
    }

    return (
        <div style={{ marginTop: '-3em' }}>
            <BoardNav boardId={match.params.id} show={show} setShow={setShow} />

            <DragDropContext onDragEnd={onDragEnd}>
                <BoardStyle style={{ display: show ? 'block' : 'none' }}>
                    <Droppable
                        droppableId="all-lists"
                        direction="horizontal"
                        type="list"
                    >
                        {(provided) => (
                            <ListsContainer
                                className="itinerary-board"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {board.lists != null
                                    ? board.lists.map((list, index) => (
                                          <List
                                              board={board}
                                              index={index}
                                              listId={list.id}
                                              key={list.id}
                                              cards={list.cards}
                                              list={list}
                                          />
                                      ))
                                    : null}
                                {provided.placeholder}
                                <Button onClick={() => handleAddList()}>
                                    <AddIcon />
                                    Add another day
                                </Button>
                            </ListsContainer>
                        )}
                    </Droppable>
                </BoardStyle>
            </DragDropContext>

            <NavContainer
                style={{ marginTop: '12px', display: show ? 'block' : 'none' }}
            >
                {selectedList?.day > 0 ? (
                    <div>
                        <Title> Day {selectedList.day} Activities Map</Title>

                        <ListOptions>
                            <Tooltip
                                title="Plot your whole itinerary on the map below"
                                placement="bottom"
                                style={{ float: 'right' }}
                            >
                                <IconButton
                                    size={'small'}
                                    aria-label="map"
                                    onClick={() => {
                                        handleAllMap();
                                    }}
                                >
                                    <MapIcon />
                                </IconButton>
                            </Tooltip>
                        </ListOptions>
                    </div>
                ) : (
                    <div>
                        <Title> Itinerary Map</Title>
                        <ListOptions>
                            <Tooltip
                                title="Plot your whole itinerary on Google map"
                                placement="bottom"
                                style={{ float: 'right' }}
                            >
                                <IconButton
                                    size={'small'}
                                    aria-label="map"
                                    onClick={() => {
                                        openDirectionMap();
                                    }}
                                >
                                    <DirectionsIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Plot your whole itinerary on the map below"
                                placement="bottom"
                                style={{ float: 'right' }}
                            >
                                <IconButton
                                    size={'small'}
                                    aria-label="map"
                                    onClick={() => {
                                        handleAllMap();
                                    }}
                                >
                                    <MapIcon />
                                </IconButton>
                            </Tooltip>
                        </ListOptions>
                    </div>
                )}

                {selectedList ? (
                    <ItineraryMap selectedList={selectedList} />
                ) : (
                    <TestMap />
                )}
            </NavContainer>

            {/*<div style={{ height: '69px' }} />*/}
        </div>
    );
}

const mapStateToProps = (state) => ({
    board: state.board,
});

export default connect(mapStateToProps)(Itinerary);
