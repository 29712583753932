import React from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';

import CustomPlaceInput from '../../../../shared/common/formFields/CustomPlaceInput';
import { updateListTargetLocation } from '../../store/actions';

export default function ListLocationModal({ location, listId, open, setOpen }) {
    const dispatch = useDispatch();

    const initialFormValue = location
        ? {
              targetLocation: location,
          }
        : {
              targetLocation: {},
          };

    return (
        <Modal open={open} onClose={() => setOpen(false)} size="mini">
            <Formik
                initialValues={initialFormValue}
                onSubmit={async (
                    values,
                    { setSubmitting, setErrors, resetForm },
                ) => {
                    try {
                        dispatch(updateListTargetLocation(listId, values));
                        resetForm();
                    } catch (error) {
                        setErrors({ auth: error.message });
                    } finally {
                        setSubmitting(false);
                        setOpen(false);
                    }
                }}
            >
                {({ isSubmitting, isValid, dirty }) => (
                    <Form className="ui form">
                        <CustomPlaceInput
                            name="targetLocation"
                            placeholder="NYC, New York, USA"
                            options={{
                                types: ['(cities)'],
                            }}
                            autoFocus
                        />
                        <Button
                            disabled={!isValid || isSubmitting || !dirty}
                            loading={isSubmitting}
                            size="small"
                            positive
                            content="Set"
                            type="submit"
                            style={{ display: 'block' }}
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
