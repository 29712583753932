import React from 'react';

import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { Segment, Header, Button, Label, Image } from 'semantic-ui-react';
import * as Yup from 'yup';

import CustomTextInput from '../../shared/common/formFields/CustomTextInput';
import { updateUserPassword } from '../../shared/firestore/firebaseService';

export default function AccountPage() {
    const { currentUser } = useSelector((state) => state.auth);

    return (
        <Segment>
            <Header
                dividing
                size="large"
                content={`Account: ${currentUser.email}`}
            />

            {currentUser.providerId === 'password' && (
                <>
                    <Header color="teal" sub content="Change Password" />
                    <p>Use this form to change your password</p>
                    <Formik
                        initialValues={{ newPassword1: '', newPassword2: '' }}
                        validationSchema={Yup.object({
                            newPassword1: Yup.string().required(
                                'Password is required',
                            ),
                            newPassword2: Yup.string().oneOf(
                                [Yup.ref('newPassword1'), null],
                                'Passwords do not match',
                            ),
                        })}
                        onSubmit={async (
                            values,
                            { setSubmitting, setErrors, setStatus },
                        ) => {
                            try {
                                await updateUserPassword(values);
                            } catch (error) {
                                setErrors({ auth: error.message });
                            } finally {
                                setSubmitting(false);
                                setStatus('sent');
                            }
                        }}
                    >
                        {({ status, errors, isSubmitting, isValid, dirty }) => (
                            <Form className="ui form">
                                {status !== 'sent' && (
                                    <>
                                        <CustomTextInput
                                            name="newPassword1"
                                            type="password"
                                            placeholder="New Password"
                                        />
                                        <CustomTextInput
                                            name="newPassword2"
                                            type="password"
                                            placeholder="Confirm password"
                                        />
                                        {errors.auth && (
                                            <Label
                                                basic
                                                color="red"
                                                style={{ marginBottom: 10 }}
                                                content={errors.auth}
                                            />
                                        )}
                                        <Button
                                            disabled={
                                                !isValid ||
                                                isSubmitting ||
                                                !dirty ||
                                                status
                                            }
                                            loading={isSubmitting}
                                            size="large"
                                            positive
                                            content="Update password"
                                            type="submit"
                                            style={{ display: 'block' }}
                                        />
                                    </>
                                )}
                                {status === 'sent' && (
                                    <>
                                        <h3 className="success">Updated!</h3>
                                        <Image src={`/assets/cat.jpg`} fluid />
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </>
            )}
            {/*{currentUser.providerId === 'google.com'}*/}
            {/*<>*/}
            {/*    <Header*/}
            {/*        color="teal"*/}
            {/*        sub*/}
            {/*        content="You can change your password using google."*/}
            {/*    />*/}
            {/*</>*/}
        </Segment>
    );
}
