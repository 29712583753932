import { UPDATE_CARD } from '../../../itineraries/store/actions';
import { updateRevs } from '../service/bucketService';
import {
    ADD_ACTIVITY,
    FETCH_ACTIVITIES,
    RESET_ACTIVITIES,
    RETAIN_STATE,
    SET_FILTER,
    UPDATE_REV_CARD,
} from './bucketConstants';

export const addActivity = (activity) => {
    return {
        type: ADD_ACTIVITY,
        payload: { activity },
    };
};

export const requestActivities = (activities) => {
    return {
        type: FETCH_ACTIVITIES,
        payload: { activities },
    };
};

export const resetActivities = () => {
    return {
        type: RESET_ACTIVITIES,
    };
};

export const retainState = () => {
    return {
        type: RETAIN_STATE,
    };
};

export function setFilter(value) {
    return function (dispatch) {
        // dispatch(clearEvents());
        dispatch({ type: SET_FILTER, payload: value });
    };
}

export const updateRevState = (rev) => {
    return {
        type: UPDATE_REV_CARD,
        payload: { rev },
    };
};

export const updateRevCard = (values) => (dispatch) => {
    dispatch(updateRevState(values));
};
