import React, { useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import { format } from 'date-fns';
import { EditText } from 'react-edit-text';
import { useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import EditLocationIcon from '@material-ui/icons/EditLocation';

import { addOneDay } from '../../../../shared/utils/utills';
import { updateListInBoard } from '../../store/actions';
import { ListOptions } from '../../styled/Boards';
import {
    Date,
    ListHeaderDiv,
    ListIcons,
    Location,
    LocationContainer, LocationOptions,
    RangedListHeader,
    RangedTitle,
    Title,
} from '../../styled/list_styled';
import ListLocationModal from './ListLocationModal';

const ListHeader = ({ board, listId, cards, index, list }) => {
    const [open, setOpen] = useState(false);
    const [listName, setListName] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (board.enableEditListName) {
            setListName(list.title || `Day ` + (index + 1));
        }
    }, [board.enableEditListName, index, list.title]);

    function updateListName({ name, value, previousValue }) {
        if (value !== previousValue) {
            dispatch(updateListInBoard(listId, { ...list, title: value }));
        }
    }

    function setTargetLocation() {
        setOpen(true);
    }

    return (
        <ListHeaderDiv>
            {board.enableEditListName ? (
                <RangedListHeader>
                    <RangedTitle>
                        <EditText
                            value={listName}
                            onChange={setListName}
                            onSave={updateListName}
                            style={{ 'marginBottom': '5px' }}
                        />
                        <Date>
                            {format(
                                addOneDay(board.startDate, index),
                                'M/d eee',
                            )}
                        </Date>

                        <LocationOptions onClick={() => setTargetLocation(cards)}>
                            {list.targetLocation?.address ? (
                                <Location>
                                    {list.targetLocation.address}
                                </Location>
                            ) : (
                                // <LocationContainer>
                                <Tooltip
                                    title="Which city will you be in? Setting this may speed up your location search"
                                    placement="bottom"
                                    style={{ float: 'right' }}
                                >
                                    <IconButton
                                        size={'small'}
                                        aria-label="set location"
                                    >
                                        <EditLocationIcon />
                                    </IconButton>
                                </Tooltip>
                                // </LocationContainer>
                            )}
                        </LocationOptions>
                    </RangedTitle>
                </RangedListHeader>
            ) : (
                <>
                    <Title>
                        {`Day ` + (index + 1)}{' '}
                        <Date>
                            {format(
                                addOneDay(board.startDate, index),
                                'M/d eee',
                            )}
                        </Date>
                    </Title>
                    <ListIcons>
                        <ListOptions onClick={() => setTargetLocation(cards)}>
                            {list.targetLocation?.address ? (
                                <Location>
                                    {list.targetLocation.address}
                                </Location>
                            ) : (
                                <LocationContainer>
                                    <Tooltip
                                        title="Which city will you be in? Setting this may speed up your location search"
                                        placement="bottom"
                                        style={{ float: 'right' }}
                                    >
                                        <IconButton
                                            size={'small'}
                                            aria-label="set location"
                                        >
                                            <EditLocationIcon />
                                        </IconButton>
                                    </Tooltip>
                                </LocationContainer>
                            )}
                        </ListOptions>
                    </ListIcons>
                </>
            )}
            <ListLocationModal
                location={list.targetLocation}
                listId={listId}
                open={open}
                setOpen={setOpen}
            />
        </ListHeaderDiv>
    );
};

export default ListHeader;
