import styled from 'styled-components';

export const ListsContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 400px;
    @media only screen and (max-width: 400px) and (min-width: 82px) {
        max-height: 500px;
    }
    @media only screen and (max-width: 2000px) and (min-width: 400px) {
        max-height: 600px;
    }

    max-width: 100%;

    overflow-x: auto;
    overflow-y: auto;

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, #d0368a 0%, #e7f2fe 0%);
        border-radius: 100px;
    }
`;
export const BoardStyle = styled.div``;
export const Button = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 8px 8px 8px;
    cursor: pointer;
    border-radius: 0.3rem;
    height: 36px;
    width: 200px;
    padding-left: 8px;
    min-width: 200px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
        Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    opacity: 1;
    color: #5e6c84;
    background-color: #fff;

    &:hover {
        background-color: #e1e2e6;
        transition: 0.3s;
    }
`;
export const Title = styled.div`
    font-size: 20px;
    line-height: 15px;
    margin: 13px 5px 0 35px;
    font-weight: bold;
    color: white;
    display: inline-block;
`;

export const NavContainer = styled.div`
    display: inline-flex;
    border-radius: 0.3rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    background-color: #557a95;
    width: 100%;
    padding: 0.5rem;
`;

export const ListOptions = styled.div`
    margin-top: 3px;
    padding: 0.4rem 0.4rem 0.4rem 0.6rem;
    float: right;
`;
