import React from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';

import CustomTextArea from '../../../shared/common/formFields/CustomTextArea';
import CustomTextInput from '../../../shared/common/formFields/CustomTextInput';
import { updateUserProfile } from '../../../shared/firestore/firestoreService';

export default function ProfileForm({ profile, setEditMode, editMode }) {
    return (
        <Formik
            initialValues={{
                displayName: profile.displayName,
                description: profile.description || '',
            }}
            validationSchema={Yup.object({
                displayName: Yup.string().required(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    await updateUserProfile(values);
                    setEditMode(!editMode);
                } catch (error) {
                    toast.error(error.message);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form className="ui form">
                    <CustomTextInput
                        name="displayName"
                        placeholder="Display Name"
                    />
                    <CustomTextArea
                        name="description"
                        placeholder="Description"
                    />
                    <Button
                        loading={isSubmitting}
                        disabled={isSubmitting || !isValid || !dirty}
                        floated="right"
                        type="submit"
                        size="large"
                        positive
                        content="Update profile"
                    />
                </Form>
            )}
        </Formik>
    );
}
