import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';

import {
    BoardStyle,
    ListsContainer,
} from '../../itineraries/styled/itinerary_styled';
import RevFilters from './filter/RevFilters';
import RevPage from './RevPage';
import { loadUserBucketList } from './service/bucketService';

export default function RevView() {
    const dispatch = useDispatch();
    const { retainState } = useSelector((state) => state.bucket);

    useEffect(() => {
        dispatch(loadUserBucketList());
        // return () => {
        //     dispatch(resetActivities());
        // };
    }, [dispatch]);

    return (
        <Grid>
            <Grid.Column width={15}>
                <BoardStyle>
                    <ListsContainer className="revs-board">
                        <RevPage />
                    </ListsContainer>
                </BoardStyle>
            </Grid.Column>
            {/*<Grid.Column width={4}>*/}
            {/*    <RevFilters loading={false} />*/}
            {/*</Grid.Column>*/}
        </Grid>
    );
}
