import firebase from '../../core/config/firebase';

const db = firebase.firestore();

/**
 * Cleans the snapshot received from firestore.
 *
 * Converts the firestore date into javascript date.
 **/
export function dataFromSnapshot(snapshot) {
    if (!snapshot.exists) return undefined;

    const data = snapshot.data();

    for (const prop in data) {
        if (data.hasOwnProperty(prop)) {
            if (data[prop] instanceof firebase.firestore.Timestamp) {
                data[prop] = data[prop].toDate();
            }
        }
    }

    return {
        ...data,
        id: snapshot.id,
    };
}

/**
 * Fetches all the events in the firestore events collection.
 **/
export function fetchEventsFromFirestore(
    filter,
    startDate,
    limit,
    lastDocSnapshot = null,
) {
    const user = firebase.auth().currentUser;

    let eventRef = db
        .collection('events')
        .orderBy('date')
        .startAfter(lastDocSnapshot)
        .limit(limit);
    switch (filter) {
        case 'isGoing':
            return eventRef
                .where('attendeeIds', 'array-contains', user.uid)
                .where('date', '>=', startDate);
        case 'isHost':
            return eventRef
                .where('hostUid', '==', user.uid)
                .where('date', '>=', startDate);
        default:
            return eventRef.where('date', '>=', startDate);
    }
}

/**
 * Fetch a single event from the events collection based on the given id.
 *
 * Single level fetching from the collection. You can use multiple layers.
 **/
export function listenToEventFromFirestore(eventId) {
    return db.collection('events').doc(eventId);
}

/**
 * Creating a new event and adding it to the events collection
 **/
export async function addEventToFirestore(event) {
    const user = firebase.auth().currentUser;
    const batch = db.batch();

    batch.set(db.collection('events').doc(), {
        ...event,
        hostUid: user.uid,
        hostedBy: user.displayName,
        hostPhotoURL: user.photoURL || null,
        attendees: firebase.firestore.FieldValue.arrayUnion({
            id: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL || null,
        }),
        attendeeIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
    });

    batch.update(db.collection('users').doc(user.uid), {
        currentEventCount: firebase.firestore.FieldValue.increment(1),
    });
    return await batch.commit();
}

/**
 * Updated an existing event based on the event id.
 **/
export function updateEventInFirestore(event) {
    return db.collection('events').doc(event.id).update(event);
}

/**
 * This is only for testing purpose and will not be on the final app.
 *
 * We will only archived data and never hard delete.
 * */
export function deleteEventInFirestore(eventId) {
    db.collection('events').doc(eventId).delete();
}

/**
 * Cancel the current event.
 **/
export function cancelEventToggle(event) {
    return db.collection('events').doc(event.id).update({
        isCancelled: !event.isCancelled,
    });
}

/**
 * Saves user data into firestore user collection.
 **/
export function setUserProfileData(user) {
    return db
        .collection('users')
        .doc(user.uid)
        .set({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL || null,
            currentEventCount: 0,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
}

/** Returns a user's profile data */
export function getUserProfile(userId) {
    return db.collection('users').doc(userId);
}

/** Updates the current user's profile data. */
export async function updateUserProfile(profile) {
    const user = firebase.auth().currentUser;

    try {
        if (user.displayName !== profile.displayName) {
            await user.updateProfile({
                displayName: profile.displayName,
            });
        }

        await firebase
            .database()
            .ref('users')
            .child(user.uid)
            .update({
                displayName: profile.displayName,
                email: user.email,
                photoURL: user.photoURL || null,
            });

        return await db.collection('users').doc(user.uid).update(profile);
    } catch (error) {
        throw error;
    }
}

/**
 * Send the user feedback to the database
 **/
export async function sendUserFeedBack(feedback) {
    const user = firebase.auth().currentUser;
    const batch = db.batch();

    batch.set(db.collection('feedback').doc(), {
        ...feedback,
        userId: user.uid,
        userEmail: user.email,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    return await batch.commit();
}
