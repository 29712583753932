import { toast } from 'react-toastify';

import firebase from '../../core/config/firebase';
import { setUserProfileData } from './firestoreService';

/** Returns an Array representation of the firebase object. */
export function firebaseObjectToArray(snapshot) {
    if (snapshot) {
        return Object.entries(snapshot).map((e) =>
            Object.assign({}, e[1], { id: e[0] }),
        );
    }
}

/** Signs in using the user email and password. */
export function signInWithEmail(creds) {
    return firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);
}

/** Signs out the current user. */
export function signOutFirebase() {
    return firebase.auth().signOut();
}

/** Sign up the user using the provided email and password. */
export async function registerInFirebase(creds) {
    try {
        const result = await firebase
            .auth()
            .createUserWithEmailAndPassword(creds.email, creds.password);

        await result.user.updateProfile({
            displayName: creds.displayName,
        });

        let user = firebase.auth().currentUser;

        user.sendEmailVerification()
            .then(function () {
                // Email sent.
            })
            .catch(function (error) {
                // An error happened.
            });

        return await setUserProfileData(result.user);
    } catch (error) {
        throw error;
    }
}

export function saveDataInFirebase() {
    const user = firebase.auth().currentUser;
    const email = user.email.replace(/[.]/g, ',');

    return firebase
        .database()
        .ref('/emailToUid/')
        .child(email)
        .update({
            uid: user.uid,
        })
        .then(() => {
            firebase
                .database()
                .ref('users')
                .child(user.uid)
                .set({
                    displayName: user.displayName,
                    email: email,
                    photoURL: user.photoURL || null,
                });
        });
}

/**
 * User decide to sign up using alternative social login.
 *
 * Currently you can use only google and facebook will become an option in the future release.
 * */
export async function socialLogin(selectedProvider) {
    let provider;
    switch (selectedProvider) {
        case 'facebook':
            provider = new firebase.auth.FacebookAuthProvider();
            break;
        case 'google':
            provider = new firebase.auth.GoogleAuthProvider();
            break;
        default:
            return;
    }

    try {
        const result = await firebase.auth().signInWithPopup(provider);
        console.log(result);
        if (result.additionalUserInfo.isNewUser) {
            await setUserProfileData(result.user);
        }
    } catch (error) {
        toast.error(error.message);
    }
}

/**
 * Updates the current user's password.
 *
 * You can only update the password if you are not signed up using social login.
 * */
export function updateUserPassword(creds) {
    const user = firebase.auth().currentUser;
    return user.updatePassword(creds.newPassword1);
}
