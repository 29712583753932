import styled from 'styled-components';

export const CardContainer = styled.div`
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #172b4d;
    background-color: white;
`;

export const StyledCardContent = styled.div`
    padding: 0 0 0 5px;
    display: inline-block;
    width: 80%;
`;
export const CardOptions = styled.div`
    visibility: hidden;
    color: #172b4d;
    opacity: 0.7;
`;

export const StyledCard = styled.div`
    word-wrap: break-word;
    border: 1px solid lightgrey;
    border-radius: 0.3rem;
    padding: 2px;
    margin-bottom: 1px;

    justify-content: space-between;
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    &:hover {
        background-color: rgb(240, 240, 240);
    }
    &:hover ${CardOptions} {
        visibility: visible;
    }
`;

export const ActivityIndex = styled.div`
    padding: 0 3px 10px 0;
    font-size: 10px;
    float: right;
    color: gray;
`;

export const CategoryCheckBoxs = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const CategoryIcon = styled.label`
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 10px;
`;

export const CategoryName = styled.div`
    padding: 5px;
`;

// ATTACHMENTS FORM STYLE BELOW //

export const Attachments = styled.div`
    display: flex;
`;

export const AttachmentsPreview = styled.div`
    width: 200px;
    padding: 10px;
`;

export const Attachment = styled.div`
    border-radius: 3px;
    min-height: 80px;
    margin: 0 0 8px;
    overflow: hidden;
    position: relative;
`;

export const AttachmentThumbnail = styled.a`
    background-color: rgba(9, 30, 66, 0.04);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    height: 80px;
    position: absolute;
    text-decoration: none;
    width: 100%;
    text-align: center;
`;
