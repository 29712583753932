import { DECREMENT_COUNTER, INCREMENT_COUNTER } from './actions';

const initialState = {
    data: 42,
};

export default function testReducer(state = initialState, action) {
    switch (action.type) {
        case INCREMENT_COUNTER:
            return {
                ...state,
                data: state.data + action.payload,
            };
        case DECREMENT_COUNTER:
            return {
                ...state,
                data: state.data - action.payload,
            };
        default:
            return state;
    }
}
