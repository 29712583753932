import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import '../assets/fomantic/dist/semantic.css';
import { Container } from 'semantic-ui-react';

import ModalManager from '../shared/common/modals/modalManager';
import LocalDevelopmentRoute from '../shared/common/routes/LocalDevelopmentRoute';
import PrivateRoute from '../shared/common/routes/PrivateRoute';
import LoadingComponent from '../shared/utils/LoadingComponent';
import AccountPage from './account/AccountPage';
import ContactPage from './contact/Contact';
import ErrorComponent from './error/ErrorComponent';
// import EventDashBoard from './events/dashboard/EventDashBoard';
// import EventDetailedPage from './events/eventDetailed/EventDetailedPage';
// import EventForm from './events/eventForm/EventForm';
import HomePage from './HomePage';
import Itineraries from './itineraries/Itineraries';
import Itinerary from './itineraries/itinerary/Itinerary';
import NavBar from './navBar/NavBar';
import ProfilePage from './profile/ProfilePage';
import RevTabView from './sandbox/RevTab';
import Sandbox from './sandbox/Sandbox';

export default function App() {
    // const { key } = useLocation();
    const { initialized } = useSelector((state) => state.async);

    if (!initialized) return <LoadingComponent content="Loading app..." />;

    return (
        <>
            <ModalManager />
            <ToastContainer position="bottom-right" hideProgressBar />
            <PrivateRoute exact path="/">
                <Redirect to="/itineraries" />
            </PrivateRoute>
            <Route
                path={'/(.+)'}
                render={() => (
                    <>
                        <NavBar />
                        <Container className="main">
                            {/*<PrivateRoute*/}
                            {/*    exact*/}
                            {/*    path="/events"*/}
                            {/*    component={EventDashBoard}*/}
                            {/*/>*/}
                            <PrivateRoute
                                exact
                                path="/contact"
                                component={ContactPage}
                            />
                            <PrivateRoute
                                exact
                                path="/itineraries"
                                component={Itineraries}
                            />
                            <LocalDevelopmentRoute
                                exact
                                path="/sandbox"
                                component={Sandbox}
                            />
                            <LocalDevelopmentRoute
                                exact
                                path="/revtabview"
                                component={RevTabView}
                            />
                            <PrivateRoute
                                exact
                                path="/itineraries/:id"
                                component={Itinerary}
                            />
                            {/*<PrivateRoute*/}
                            {/*    path="/events/:id"*/}
                            {/*    component={EventDetailedPage}*/}
                            {/*/>*/}
                            {/*<PrivateRoute*/}
                            {/*    path={['/createEvent', '/manage/:id']}*/}
                            {/*    key={key}*/}
                            {/*    component={EventForm}*/}
                            {/*/>*/}
                            <PrivateRoute
                                path="/error"
                                component={ErrorComponent}
                            />
                            <PrivateRoute
                                path="/profile/:id"
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                path="/account"
                                component={AccountPage}
                            />
                        </Container>
                    </>
                )}
            />
        </>
    );
}
