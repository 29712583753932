import React from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';

import CustomTextInput from '../../shared/common/formFields/CustomTextInput';
import { createBoard, loadUserBoards } from './store/actions';

export default function CreateItineraryModal({ open, setOpen }) {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <Modal open={open} size="mini" onClose={() => setOpen(false)}>
            <Modal.Header content="Let's name your itinerary" />
            <Modal.Content>
                <Formik
                    initialValues={{ title: '' }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            const key = dispatch(createBoard(values));
                            dispatch(loadUserBoards());
                            setSubmitting(false);
                            history.push(`/itineraries/${key}`);
                        } catch (error) {
                            toast.error(error.message);
                            setSubmitting(false);
                        } finally {
                            setOpen(false);
                        }
                    }}
                >
                    {({ isSubmitting, isValid, dirty }) => (
                        <Form className="ui form">
                            <CustomTextInput
                                name="title"
                                placeholder="Example: Asia 2022"
                                autoComplete="off"
                                autoFocus
                            />
                            <Button
                                loading={isSubmitting}
                                disabled={!isValid || !dirty || isSubmitting}
                                type="submit"
                                fluid
                                size="small"
                                color="blue"
                                content="Create"
                            />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
        </Modal>
    );
}
