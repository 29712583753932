import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { openModal } from '../../shared/common/modals/store/modalActions';
import ListDateRangePicker from './ListDateRangePicker';
import RevView from './rev/RevView';
import { decrement, increment } from './store/actions';
import TestMap from './TestMap';
import TestPlaceInput from './TestPlaceInput';
// import { Sheet } from './sheet/Sheet';

const RevTabView = () => {

    return (
        <>
            <RevView />
        </>
    );
};

export default RevTabView;
