import { ObjectID } from 'bson';
import { toast } from 'react-toastify';

import firebase from '../../../../core/config/firebase';
import { db } from '../../service/firebaseService';
import { getUser } from '../../service/user';
import { RESET_BOARD, RESET_BOARDS } from './boardConstants';
import { RESET_THEME } from './theme';

export const CREATE_BOARD_REQUEST = 'CREATE_BOARD_REQUEST';
export const CREATE_BOARD_SUCCESS = 'CREATE_BOARD_SUCCESS';
export const CREATE_BOARD_FAIL = 'CREATE_BOARD_FAIL';

export const GET_BOARDS_REQUEST = 'GET_BOARDS_REQUEST';
export const GET_BOARDS_SUCCESS = 'GET_BOARDS_SUCCESS';
export const GET_BOARDS_FAIL = 'GET_BOARDS_FAIL';

export const UPDATE_BOARD_TITLE = 'UPDATE_BOARD_TITLE';

export const GET_BOARD_NAME_SUCCESS = 'GET_BOARD_NAME_SUCCESS';

function newID() {
    return new ObjectID().toHexString();
}

// Get Boards
const requestBoards = () => {
    return {
        type: GET_BOARDS_REQUEST,
    };
};
export const receiveBoards = (boards) => {
    return {
        type: GET_BOARDS_SUCCESS,
        boards,
    };
};
const receiveBoardsError = () => {
    return {
        type: GET_BOARDS_FAIL,
    };
};

// Create Board
const requestCreateBoard = () => {
    return {
        type: CREATE_BOARD_REQUEST,
    };
};
const receiveCreateBoard = (id) => {
    return {
        type: CREATE_BOARD_SUCCESS,
        id,
    };
};
const createBoardError = () => {
    return {
        type: CREATE_BOARD_FAIL,
    };
};

///////////////////////////** Start Board name **///////////////////////////////
const receiveBoardName = (name, boardId) => {
    return {
        type: GET_BOARD_NAME_SUCCESS,
        name,
        boardId,
    };
};

export const updateBoardName = (boardName, boardId) => (dispatch) => {
    db.ref('board').child(boardId).update({ title: boardName });
    db.ref('boards').child(boardId).update({ title: boardName });
    dispatch({
        type: UPDATE_BOARD_TITLE,
        title: boardName,
    });
};

export const listenBoardName = (boardId) => (dispatch) => {
    db.ref('boards')
        .child(boardId)
        .on('value', (snapshot) => {
            if (snapshot.val() != null)
                dispatch(receiveBoardName(snapshot.val().title, boardId));
        });
};

///////////////////////////** Board actions **//////////////////////////////////

export const loadUserBoards = () => (dispatch) => {
    dispatch(requestBoards());
    const user = getUser();
    // Get all of the current user's boards from userBoards.
    // Then get all the boards titles from boards using the id
    let boards = [];

    db.ref('userBoards')
        .child(user.uid)
        .once('value', (snapshot) => {
            snapshot.forEach((data) => {
                db.ref('boards')
                    .child(data.key)
                    .once('value', (snap) => {
                        if (snap.exists()) {
                            boards.push({
                                key: data.key,
                                title: snap.val().title,
                                uid: snap.val().uid,
                                archived: snap.val().archived,
                                favorited: snap.val().favorited,
                                theme: snap.val().theme,
                            });
                        }
                    })
                    .then(() => {
                        dispatch(receiveBoards(boards));
                    });
            });
        });
};

export const createBoard =
    ({ title }) =>
    (dispatch) => {
        dispatch(requestCreateBoard());
        const user = getUser();

        if (!user) {
            dispatch(createBoardError());
        } else {
            const uid = user.uid;
            const key = db.ref('boards').push(
                {
                    title: title,
                    uid: user.uid,
                },
                (err) => {
                    if (err) {
                        dispatch(createBoardError());
                    }
                },
            ).key;

            db.ref('userBoards').child(uid).child(key).set(true);

            const day1 = { id: newID(), title: 'Day 1' };

            db.ref('board')
                .child(key)
                .set({
                    id: key,
                    uid: user.uid,
                    title: title,
                    archived: false,
                    favorited: false,
                    startDate: firebase.database.ServerValue.TIMESTAMP,
                    createdOn: firebase.database.ServerValue.TIMESTAMP,
                    theme: '',
                    lists: { 0: day1 },
                    selectedList: { ...day1, day: 1 },
                });

            db.ref('board/' + key + '/attendees')
                .child(uid)
                .set(true);

            firebase
                .firestore()
                .collection('itineraries')
                .doc(key)
                .set({
                    attendees: firebase.firestore.FieldValue.arrayUnion({
                        id: user.uid,
                        displayName: user.displayName,
                        photoURL: user.photoURL || null,
                    }),
                    attendeeIds: firebase.firestore.FieldValue.arrayUnion(
                        user.uid,
                    ),
                });

            dispatch(receiveCreateBoard(key));
            return key;
        }
    };

export const addUserToBoard = (email, boardId) => (dispatch) => {
    const emailWithoutDot = email.toLowerCase().replace(/[.]/g, ',');

    // get userId from email
    let invitedUser;
    // Check to see if the given email match to a current user.
    db.ref('emailToUid')
        .child(emailWithoutDot)
        .once('value')
        .then((snapshot) => {
            if (snapshot.val()) {
                invitedUser = snapshot.val();
            }
            // console.log(
            //     'Found the user record in db user is registered',
            //     invitedUser,
            // );
            return invitedUser;
        })
        .then((user) => {
            if (user) {
                // if the user exist then we add the itinerary to the user. if the user is not already going
                // console.log(
                //     'found user:',
                //     user,
                //     "let's make sure not already going",
                // );

                db.ref('userBoards')
                    .child(invitedUser.uid)
                    .child(boardId)
                    .get()
                    .then((snapshot) => {
                        return snapshot.exists();
                    })
                    .then((exist) => {
                        // console.log('already joined?', exist);
                        if (!exist) {
                            // console.log('nope added!');
                            db.ref('userBoards')
                                .child(invitedUser.uid)
                                .child(boardId)
                                .update({ owner: false });

                            db.ref('board/' + boardId + '/attendees')
                                .child(invitedUser.uid)
                                .set(true);

                            firebase
                                .firestore()
                                .collection('users')
                                .doc(invitedUser.uid)
                                .get()
                                .then(function (doc) {
                                    if (doc.exists) {
                                        firebase
                                            .firestore()
                                            .collection('itineraries')
                                            .doc(boardId)
                                            .update({
                                                attendees:
                                                    firebase.firestore.FieldValue.arrayUnion(
                                                        {
                                                            id: invitedUser.uid,
                                                            displayName:
                                                                doc.data()
                                                                    .displayName,
                                                            photoURL:
                                                                doc.data()
                                                                    .photoURL,
                                                        },
                                                    ),
                                                attendeeIds:
                                                    firebase.firestore.FieldValue.arrayUnion(
                                                        invitedUser.uid,
                                                    ),
                                            });
                                    } else {
                                        // doc.data() will be undefined in this case
                                        // console.log('No such document!');
                                    }
                                });
                        }
                    });
            } else {
                // console.log('user not signed up maybe send an invite to user');
                throw 'User is not signed up';
            }
        })
        .catch((error) => {
            toast.error(
                'Your friend is not signed up yet tell her/him to sign up!',
            );
        });
};

// You are only allow to delete the board that you are not the owner for.
export const deleteUserFromBoard = (boardId, userId) => (dispatch) => {
    // Get a list of all userIds from '/boards/boardId/attendees/'
    // Go through that list and delete all occurrences of boardId in each userId
    db.ref('board/' + boardId + '/attendees')
        .child(userId)
        .remove();

    firebase
        .firestore()
        .collection('itineraries')
        .doc(boardId)
        .get()
        .then(function (doc) {
            if (doc.exists) {
                firebase
                    .firestore()
                    .collection('itineraries')
                    .doc(boardId)
                    .update({
                        attendees: doc
                            .data()
                            .attendees.filter(
                                (attendee) => attendee.id !== userId,
                            ),
                        attendeeIds:
                            firebase.firestore.FieldValue.arrayRemove(userId),
                    });
            } else {
                // doc.data() will be undefined in this case
                // console.log('No such document!');
            }
        })
        .catch(function (error) {
            console.log('Error getting document:', error);
        });

    return db.ref('userBoards').child(userId).child(boardId).remove();
};

/// RESET

export const resetBoards = () => {
    return {
        type: RESET_BOARDS,
    };
};

export const resetBoard = () => {
    return {
        type: RESET_BOARD,
    };
};

export const resetTheme = () => {
    return {
        type: RESET_THEME,
    };
};
