export const GET_BOARD_REQUEST = 'GET_BOARD_REQUEST';
export const GET_BOARD_SUCCESS = 'GET_BOARD_SUCCESS';
export const GET_BOARD_FAIL = 'GET_BOARD_FAIL';

export const UPDATE_BOARD_REQUEST = 'UPDATE_BOARD_REQUEST';
export const UPDATE_BOARD_SUCCESS = 'UPDATE_BOARD_SUCCESS';
export const UPDATE_BOARD_FAIL = 'UPDATE_BOARD_FAIL';

export const ADD_LIST = 'ADD_LIST';
export const UPDATE_LIST = 'UPDATE_LIST';
export const DELETE_LIST = 'DELETE_LIST';
export const GET_LISTS = 'GET_LISTS';

export const ADD_CARD = 'ADD_CARD';
export const UPDATE_CARD = 'UPDATE_CARD';
export const DELETE_CARD = 'DELETE_CARD';

export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';

export const DRAG_HAPPENED = 'DRAG_HAPPENED';

export const ARCHIVED_BOARD = 'ARCHIVED_BOARD';
export const UNARCHIVED_BOARD = 'UNARCHIVED_BOARD';

export const FAVORITED_BOARD = 'FAVORITED_BOARD';
export const UNFAVORITED_BOARD = 'UNFAVORITED_BOARD';

export const UPDATE_ITINERARY_DATE = 'UPDATE_ITINERARY_DATE';

export const SET_TARGET_LOCATION = 'SET_TARGET_LOCATION';

export const SET_SELECTED_LIST = 'SET_SELECTED_LIST';

export const RESET_BOARDS = 'RESET_BOARDS';
export const RESET_BOARD = 'RESET_BOARD';
