import styled from 'styled-components';

export const ListStyle = styled.div`
    border: ${(props) => props.theme};
    background-color: white;
    border-radius: 0.3rem;
    min-width: 260px;
    height: 100%;
    max-height: 480px;
    
    @media only screen and (max-width: 2000px) and (min-width: 400px) {
        max-height: 600px;
    }
    
    margin: 8px 0px 0px 8px;
    position: relative;
    white-space: normal;
    overflow-y: auto;
    overflow-x: hidden;
`;
export const ListIcons = styled.div`
    position: absolute;
    right: 0;
`;
export const RangedListHeader = styled.div`
    width: 100%;
    display: table-row;
`;
export const Title = styled.div`
    color: #172b4d;
    font-size: 14px;
    line-height: 15px;
    margin: 8px 15px;
    font-weight: bold;
    opacity: 0.7;
    display: inline-block;
`;
export const RangedTitle = styled.div`
    width: 90%;
    color: #172b4d;
    font-size: 14px;
    line-height: 15px;
    margin: 8px 15px 0px 15px;
    font-weight: bold;
    opacity: 0.7;
    display: inline-block;
`;
export const LocationOptions = styled.div`
    border-radius: 0.3rem;
    padding: 0 0.4rem 0.4rem 0.6rem;
    color: #172b4d;
    float: right;
    min-height: 32px;
`;
export const Date = styled.div`
    color: #172b4d;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    opacity: 0.7;
`;
export const Location = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: gray;
    font-size: 12px;
    //line-height: 15px;
    max-width: 150px;
    //text-align: right;
    cursor: pointer;
    border-radius: 0.3rem;

    &:hover {
        background-color: #e1e2e6;
    }
`;
export const HelperText = styled.div`
    display: flex;
    align-items: center;
    color: red;
    font-size: 12px;
    font-weight: bold;
`;
export const LocationContainer = styled.div`
    display: flex;
    vertical-align: center;
    color: gray;
    font-size: 12px;
`;
export const DroppableCards = styled.div`
    min-height: 20px;
`;
export const ListHeaderDiv = styled.div`
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
`;
