import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import asyncReducer from '../../shared/common/async/store/asyncReducer';
import modalReducer from '../../shared/common/modals/store/modalReducer';
import authReducer from '../../shared/common/registration/store/authReducer';
import eventReducer from '../events/store/eventReducer';
import boardReducer from '../itineraries/store/reducers/board';
import boardsReducer from '../itineraries/store/reducers/boards';
import themeReducer from '../itineraries/store/reducers/theme';
import profileReducer from '../profile/store/profileReducer';
import bucketReducer from '../sandbox/rev/store/bucketReducer';
import testReducer from '../sandbox/store/testReducer';

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        async: asyncReducer,
        board: boardReducer,
        boards: boardsReducer,
        bucket: bucketReducer,
        event: eventReducer,
        modals: modalReducer,
        profile: profileReducer,
        theme: themeReducer,

        // Testing reducer
        test: testReducer,
    });

export default rootReducer;
