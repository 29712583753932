import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

import firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: 'revney-fe.firebaseapp.com',
    databaseURL: 'https://revney-fe.firebaseio.com',
    projectId: 'revney-fe',
    storageBucket: 'revney-fe.appspot.com',
    messagingSenderId: '249225724785',
    appId: '1:249225724785:web:f9150a7aaaa7944208acd0',
    measurementId: 'G-8L2Z0XFXLW',
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
