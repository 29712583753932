import styled from 'styled-components';

import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

export const SettingButton = styled(Button)`
    border-radius: 21px !important;
    height: 42px !important;
    margin-top: 5px !important;
    border: 1px solid #557a95 !important;

    &:hover {
        background-color: rgba(256, 256, 256, 0.3) !important;
        transition: 0.3s;
    }
`;

export const SettingItem = styled(MenuItem)`
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
`;
