import styled from 'styled-components';

export const NavContainer = styled.div`
    display: inline-flex;
    border-radius: 0.3rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    background-color: #557a95;
    width: 100%;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
`;

export const Nav = styled.div`
    display: flex;
    align-items: center;
`;

export const NavActionsWrapper = styled.div`
    display: inline-flex;
`;

export const MenuActionButton = styled.div`
    border-radius: 0.3rem;
    color: white;
    padding: 0.6rem;
    margin-right: 0.4rem;
    min-height: 1em;

    font-size: 18px;
    font-weight: bold;
    align-items: center;

    &:hover {
        background-color: #90acc0;
        cursor: pointer;
    }
`;

export const MenuButton = styled.div`
    border-radius: 0.3rem;
    padding: 0.4rem 0.4rem 0.4rem 0.6rem;
    margin-right: 0.4rem;
    //top: 50%;
    align-self: center;
    //background-color: #6f93ad;
    color: white;

    &:hover {
        background-color: #90acc0;
        cursor: pointer;
    }
`;

export const MoreButton = styled.div`
    display: flex;
    align-items: center;
`;
