import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';

import SignedInMenu from '../../shared/common/registration/SignedInMenu';
import SignedOutMenu from '../../shared/common/registration/SignedOutMenu';

const NavBar = () => {
    const { authenticated } = useSelector((state) => state.auth);
    const [showRoute, setShowRoute] = useState(false);

    useEffect(() => {
        setShowRoute(authenticated && process.env.NODE_ENV === 'development');
    }, [authenticated]);

    return (
        <Menu inverted fixed="top">
            <Container>
                {authenticated ? (
                    <NavLink exact to="/itineraries">
                        {/*<a>*/}
                        <img
                            style={{
                                height: '32px',
                                marginRight: '10px',
                                marginLeft: '10px',
                                marginTop: '8px',
                            }}
                            src="/assets/logo.png"
                            alt=""
                        />
                        {/*</a>*/}
                    </NavLink>
                ) : (
                    <NavLink exact to="/">
                        {/*<a>*/}
                        <img
                            style={{
                                height: '32px',
                                marginRight: '10px',
                                marginLeft: '10px',
                                marginTop: '8px',
                            }}
                            src="/assets/logo.png"
                            alt=""
                        />
                        {/*</a>*/}
                    </NavLink>
                )}

                {/*<Menu.Item name="Events" as={NavLink} exact to="/events" />*/}
                <Menu.Item
                    name="Itineraries"
                    as={NavLink}
                    exact
                    to="/itineraries"
                />
                {showRoute && (
                    <Menu.Item name="Rev" as={NavLink} exact to="/revtabview" />
                )}
                {showRoute && (
                    <Menu.Item
                        name="Sandbox"
                        as={NavLink}
                        exact
                        to="/sandbox"
                    />
                )}

                <Menu.Item name="Contact us" as={NavLink} exact to="/contact" />
                {/*<Menu.Item name="Sandbox" as={NavLink} exact to="/sandbox" />*/}
                {/*{authenticated && (*/}
                {/*    <Menu.Item header as={NavLink} to="/createEvent">*/}
                {/*        <Button*/}
                {/*            positive*/}
                {/*            inverted*/}
                {/*            content="Create RevCard"*/}
                {/*        />*/}
                {/*    </Menu.Item>*/}
                {/*)}*/}
                {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
            </Container>
        </Menu>
    );
};

export default NavBar;
