import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { categoryData } from '../../../core/config/categoryOptions';
import CustomSelectInput from '../../../shared/common/formFields/CustomSelectInput';
import CustomTextInput from '../../../shared/common/formFields/CustomTextInput';
import RevLocationForm from './form/RevLocationForm';
import { addActivityToFirestore } from './service/bucketService';

export default function ActivityForm({ open, setOpen }) {
    const dispatch = useDispatch();
    const validationSchema = Yup.object({
        title: Yup.string().required('Please enter an activity name'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            dispatch(addActivityToFirestore(values));

            setSubmitting(false);
            setOpen(false);
        } catch (error) {
            toast.error(error.message);
            setSubmitting(false);
            setOpen(false);
        }
    };

    return (
        <Modal open={open} size="mini" onClose={() => setOpen(false)}>
            <Modal.Content>
                <Formik
                    initialValues={{
                        title: '',
                        location: {
                            address: '',
                            latLng: null,
                        },
                        category: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        await handleSubmit(values, { setSubmitting });
                    }}
                >
                    {({ isSubmitting, isValid, dirty }) => (
                        <Form className="ui form">
                            <label>Activity Name</label>
                            <CustomTextInput
                                name="title"
                                placeholder="Tokyo Disneyland*"
                                autoComplete="off"
                            />
                            {RevLocationForm()}
                            {/*<label>Category</label>*/}
                            {/*<CustomSelectInput*/}
                            {/*    name="category"*/}
                            {/*    options={categoryData}*/}
                            {/*    placeholder="select a category"*/}
                            {/*/>*/}
                            <Button
                                loading={isSubmitting}
                                disabled={!isValid || !dirty || isSubmitting}
                                type="submit"
                                fluid
                                size="small"
                                color="teal"
                                content="Add"
                            />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
        </Modal>
    );
}
