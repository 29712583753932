import React, { useEffect, useState } from 'react';

import GoogleMapReact from 'google-map-react';
import { useStore } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

const K_WIDTH = 30;
const K_HEIGHT = 30;

const greatPlaceStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH,
    top: -K_HEIGHT,

    border: '5px solid #f44336',
    borderRadius: K_HEIGHT,
    backgroundColor: 'white',
    textAlign: 'center',
    color: '#3f51b5',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
};

const HelperMapText = styled.div`
    color: white;
    font-size: 16px;
    line-height: 15px;
    margin-top: 20px;
    font-weight: bold;
    display: inline-block;
`;

// InfoWindow component
export function InfoWindow({ activity }) {
    const infoWindowStyle = {
        position: 'relative',
        bottom: 150,
        left: '-100px',
        width: 220,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 100,
    };

    const linkButton = {
        backgroundColor: 'transparent',
        color: 'blue',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'inline',
        margin: 0,
        padding: 0,
        fontSize: 14,
    };

    function openMap(location) {
        if (window.confirm('Open in Google maps?')) {
            window.open('https://maps.google.com?q=' + location);
        }
    }

    return (
        <div style={infoWindowStyle}>
            <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                {activity.text}
            </div>
            <div
                style={{ fontSize: 12, marginTop: '5px', marginBottom: '5px' }}
            >
                {activity.location.address}
            </div>
            <div
                style={linkButton}
                onClick={() => openMap(activity.location.address)}
            >
                View in Google Maps
            </div>
        </div>
    );
}

export function Marker({ show, activity, text }) {
    return (
        <>
            <div style={greatPlaceStyle}>{text}</div>
            {show && <InfoWindow activity={activity} />}
        </>
    );
}

const ItineraryMap = ({ selectedList }) => {
    const store = useStore();
    const zoom = 10;
    const [firstLocation, setFirstLocation] = useState(null);
    const [activities, setActivities] = useState(null);

    useEffect(() => {
        let location = null;

        if (selectedList?.cards?.length > 0) {
            const list = selectedList.cards.filter(
                (card) => card.location.latLng,
            );
            if (list.length > 0) {
                location = list[0].location.latLng;
            } else if (
                list.length === 0 &&
                selectedList?.targetLocation?.latLng
            ) {
                location = selectedList.targetLocation.latLng;
            }
        } else if (selectedList?.targetLocation?.latLng) {
            location = selectedList.targetLocation.latLng;
        }

        setFirstLocation(location);

        selectedList.cards &&
            selectedList.cards.forEach((result) => {
                result.show = false;
            });

        setActivities(selectedList.cards);
    }, [selectedList, store]);

    const onChildClick = (key) => {
        activities[key].show = !activities[key].show;
        setActivities({ ...activities });
    };

    return (
        <>
            <Segment
                style={{
                    padding: 0,
                    width: '100%',
                    backgroundColor: 'inherit',
                    border: 'none',
                }}
            >
                <div
                    style={{
                        height: 500,
                        width: '100%',
                        border: 'none',
                    }}
                >
                    {firstLocation ? (
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: process.env.REACT_APP_MAPS_KEY,
                                libraries: ['places'],
                            }}
                            center={firstLocation}
                            onChildClick={onChildClick}
                            defaultZoom={zoom}
                            hoverDistance={30}
                            layerTypes={['TrafficLayer', 'Marker']}
                            options={{
                                styles: [
                                    {
                                        featureType: 'all',
                                        elementType: 'labels',
                                        stylers: [{ visibility: 'on' }],
                                    },
                                ],
                            }}
                        >
                            {selectedList.cards &&
                                selectedList.cards.map((activity, index) => {
                                    if (activity.location.latLng) {
                                        return (
                                            <Marker
                                                key={index}
                                                text={index + 1}
                                                activity={activity}
                                                show={activity.show}
                                                lat={
                                                    activity.location.latLng.lat
                                                }
                                                lng={
                                                    activity.location.latLng.lng
                                                }
                                            />
                                        );
                                    }
                                    return null;
                                })}
                        </GoogleMapReact>
                    ) : (
                        <HelperMapText>
                            Start adding activities and set location to your
                            activities.
                        </HelperMapText>
                    )}
                </div>
            </Segment>
        </>
    );
};

export default ItineraryMap;
