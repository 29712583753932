export const categoryData = [
    { key: 'food', text: 'Food', value: 'food' },
    { key: 'sightseeing', text: 'Sightseeing', value: 'sightseeing' },
    { key: 'activity', text: 'Activity', value: 'activity' },
    { key: 'event', text: 'Event', value: 'event' },
    { key: 'location', text: 'Location', value: 'location' },
].sort((a, b) => {
    if (a.text < b.text) {
        return -1;
    }
    if (a.text > b.text) {
        return 1;
    }
    // names must be equal
    return 0;
});


export const contactData = [
    { key: 'bug', text: 'Bug', value: 'bug' },
    { key: 'suggestion', text: 'Suggestion', value: 'suggestion' },
    { key: 'other', text: 'Other', value: 'other' },
];
