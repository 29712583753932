import React, { useState } from 'react';

import { Tooltip } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { addCardToExactBoard, deleteCardFromBoard } from '../../../store/actions';
import {
    CardOptions,
    CardContainer,
    StyledCard,
    StyledCardContent,
    ActivityIndex,
} from '../../../styled/activity_styled';
import ActivityModal from './ActivityModal';
import ActivityTime from './ActivityTime';
import { addActivityToFirestore } from '../../../../sandbox/rev/service/bucketService';

const ActivityCard = ({ card, boardId, index, listId, targetLocation }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const activityNum = <>{index + 1} </>;

    function handleDeleteActivity() {
        console.log(card);
        dispatch(addActivityToFirestore(card));
        // add the whole board to another itinerary for now
        // dispatch(addCardToExactBoard(0, "-NGszajXQlPyU65DIBca", card));
        dispatch(deleteCardFromBoard(card.id, listId));
    }

    return (
        <Draggable
            key={String(listId + card.id)}
            draggableId={String(card.id)}
            index={index}
        >
            {(provided) => (
                <CardContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <StyledCard>
                        <StyledCardContent onClick={() => setOpen(true)}>
                            {card.time && <ActivityTime card={card} />}
                            {card.text}
                        </StyledCardContent>

                        {card.checked?.length > 0 &&
                            card.checked.map((icon, index) => (
                                <div
                                    key={index}
                                    style={{ alignItems: 'center' }}
                                    onClick={() => setOpen(true)}
                                >
                                    <i
                                        className={icon + ' icon'}
                                        style={{ color: '#5e6c84' }}
                                    />
                                </div>
                            ))}

                        <CardOptions>
                            <Tooltip
                                title="Delete activity"
                                placement="bottom"
                                style={{ float: 'right' }}
                            >
                                <IconButton
                                    size={'small'}
                                    aria-label="delete"
                                    onClick={() => {
                                        handleDeleteActivity();
                                        // if (
                                        //     window.confirm(
                                        //         'Are you sure you wish to delete this item?',
                                        //     )
                                        // ) {
                                        //     handleDeleteActivity();
                                        // }
                                    }}
                                >
                                    <DeleteIcon fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                        </CardOptions>
                        <ActivityIndex> {activityNum}</ActivityIndex>
                    </StyledCard>
                    <ActivityModal
                        open={open}
                        setOpen={setOpen}
                        card={card}
                        boardId={boardId}
                        listId={listId}
                        targetLocation={targetLocation}
                    />
                </CardContainer>
            )}
        </Draggable>
    );
};

export default ActivityCard;
