import firebase from '../../../../core/config/firebase';
import { dataFromSnapshot } from '../../../../shared/firestore/firestoreService';
import {
    addActivity,
    requestActivities,
    retainState,
} from '../store/bucketActions';

const db = firebase.firestore();

export const loadUserBucketList = () => async (dispatch) => {
    // dispatch(resetActivities());

    const bucketSnapshot = await fetchBucketListFromFirestore().get();
    const activities = bucketSnapshot.docs.map((doc) => dataFromSnapshot(doc));

    dispatch(requestActivities(activities));
    // dispatch(retainState);
};

/**
 * Fetches all the bucket list in the firestore user->bucketList collection.
 **/
function fetchBucketListFromFirestore() {
    const user = firebase.auth().currentUser;

    return (
        db
            .collection('users')
            .doc(user.uid)
            .collection('revs')
            .orderBy('createdOn')
            // .startAfter(lastDocSnapshot)
            // .limit(10)
    );
    // switch (filter) {
    //     case 'isGoing':
    //         return eventRef
    //             .where('attendeeIds', 'array-contains', user.uid)
    //             .where('date', '>=', startDate);
    //     case 'isHost':
    //         return eventRef
    //             .where('hostUid', '==', user.uid)
    //             .where('date', '>=', startDate);
    //     default:
    //         return eventRef.where('date', '>=', startDate);
}

/**
 * Adds an activity to the bucket collection
 **/
export const addActivityToFirestore = (activity) => async (dispatch) => {
    const user = firebase.auth().currentUser;
    dispatch(addActivity(activity));

    return await db
        .collection('users')
        .doc(user.uid)
        .collection('revs')
        .add({
            ...activity,
            ownerId: user.uid,
            title: activity.text ?? activity.title,
            createdOn: firebase.firestore.FieldValue.serverTimestamp(),
        });
};

/**
 * Updates revs collection using new state
 **/
export const updateRevs = (id, rev) => {
    const user = firebase.auth().currentUser;

    return db
        .collection('users')
        .doc(user.uid)
        .collection('revs')
        .doc(id)
        .update(rev);
};
