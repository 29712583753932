import cuid from 'cuid';
import { toast } from 'react-toastify';

import firebase from '../../../core/config/firebase';
import { getFileExtension } from '../../../shared/utils/utills';
import { updateCardFromBoard } from '../store/actions';

export const db = firebase.database();

export function uploadAttachmentToFirebaseStorage(file, filename) {
    const user = firebase.auth().currentUser;
    const storageRef = firebase.storage().ref();

    const metadata = {
        contentType: 'image/jpeg/pdf',
    };

    return storageRef
        .child(`${user.uid}/itineraries/${filename}`)
        .put(file, metadata);
}

export function deleteAttachmentFromFirebaseStorage(filename) {
    const userUid = firebase.auth().currentUser.uid;
    const storageRef = firebase.storage().ref();
    const photoRef = storageRef.child(`${userUid}/itineraries/${filename}`);

    return photoRef.delete();
}

/**
 * We will upload the attachment to the cloud storage stored under the current
 * user's id/itineraries.
 *
 * The attachment URL and filename will be stored in the activity level,
 * itinerary level, and user profile level.
 *
 * Activity name will be stored at itinerary level.
 *
 * Other data such as boardId and any necessary information will be stored at
 * the user level.
 **/
export function uploadToStorage(dispatch, listId, oldAttachments, values) {
    const filename =
        cuid() + '.' + getFileExtension(values.attachments[0].path);
    const uploadTask = uploadAttachmentToFirebaseStorage(
        values.attachments[0],
        filename,
    );

    uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
            toast.error(error.message);
        },
        () => {
            uploadTask.snapshot.ref.getDownloadURL().then((attachmentURL) => {
                oldAttachments.push({ filename, attachmentURL });

                dispatch(updateCardFromBoard(listId, oldAttachments, values));
            });
        },
    );
}
