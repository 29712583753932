import React from 'react';

import { useSelector } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';

export default function ErrorComponent() {
    const { error } = useSelector((state) => state.async);

    return (
        <Segment placeholder>
            <Header
                textAlign="center"
                content={error?.message || 'Oops - we have an error'}
            />
        </Segment>
    );
}
