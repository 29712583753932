import React, { useState } from 'react';

import GoogleMapReact from 'google-map-react';
import { Segment } from 'semantic-ui-react';

export default function TestMap() {
    const defaultProps = {
        center: {
            lat: 39.95,
            lng: -75.33,
        },
        zoom: 11,
    };
    const [location] = useState(defaultProps);

    return (
        // Important! Always set the container height explicitly
        <Segment
            style={{
                padding: 0,
                width: '100%',
            }}
        >
            <div style={{ height: 500, width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_MAPS_KEY,
                    }}
                    center={location.center}
                    zoom={location.zoom}
                >
                    {/*{leave/!**!/}*/}
                </GoogleMapReact>
            </div>
        </Segment>
    );
}
