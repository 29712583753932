import React from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Divider, Label } from 'semantic-ui-react';
import * as Yup from 'yup';

import CustomTextInput from '../../formFields/CustomTextInput';
import ModalWrapper from '../../modals/modalWrapper';
import { closeModal, openModal } from '../../modals/store/modalActions';
import { resetPassword } from '../store/authActions';
// import SocialLogin from './SocialLogin';

export default function ResetPasswordForm() {
    const dispatch = useDispatch();

    return (
        <ModalWrapper size="mini" header="Reset your password">
            <Formik
                initialValues={{ email: '' }}
                validationSchema={Yup.object({
                    email: Yup.string().required().email(),
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await resetPassword(values.email);
                        setSubmitting(false);
                        dispatch(closeModal());
                    } catch (error) {
                        setErrors({
                            auth: 'Bad email',
                        });
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, isValid, dirty, errors }) => (
                    <Form className="ui form">
                        <CustomTextInput
                            name="email"
                            placeholder="Email Address"
                        />
                        {errors.auth && (
                            <Label
                                basic
                                color="red"
                                style={{ marginBottom: 10 }}
                                content={errors.auth}
                            />
                        )}
                        <Button
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type="submit"
                            fluid
                            size="large"
                            color="teal"
                            content="Send"
                        />
                        <Divider horizontal>Or</Divider>
                        {/*<SocialLogin />*/}
                        <Button
                            fluid
                            color="teal"
                            content="Login"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: 'LoginForm',
                                    }),
                                )
                            }
                        />
                        <Button
                            style={{ marginTop: '5px' }}
                            // disabled={true}
                            fluid
                            color="green"
                            content="Sign up"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: 'RegisterForm',
                                    }),
                                )
                            }
                        />
                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    );
}
