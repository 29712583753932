import styled from 'styled-components';

export const CardOptions = styled.div`
    float: right;
    visibility: hidden;
    border-radius: 0.3rem;
`;
export const Tile = styled.div`
    position: relative;
    display: inline-block;
    padding: 4px;
    width: 15%;
    height: 80px;
    background-color: #7093ad;
    color: white;
    margin: 0.5%;
    border-radius: 0.3rem;
    transition: all 0.3s;
    background-image: ${(props) => 'url(' + props.theme + ')'};
    background-size: cover;
    background-position: 50%;

    &:hover {
        background-color: #0e566c;
        opacity: 0.75;
    }

    @media only screen and (max-width: 400px) and (min-width: 82px) {
        width: 96%;
        margin: 2%;
    }
    @media only screen and (max-width: 720px) and (min-width: 400px) {
        width: 48%;
    }
    @media only screen and (max-width: 1024px) and (min-width: 720px) {
        width: 30%;
    }
    @media only screen and (max-width: 1480px) and (min-width: 1024px) {
        width: 20%;
    }

    &:hover ${CardOptions} {
        visibility: visible;
    }
`;
export const Content = styled.div`
    position: relative;
    display: inline-block;
    padding: 4px;
    width: 100%;
    height: 100%;
    color: white;
    margin: 0.5%;
    border-radius: 0.3rem;
    transition: all 0.3s;

    &:hover {
        color: white;
    }
`;
export const TileText = styled.div`
    text-align: center;
    top: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    font-weight: bold;
`;
