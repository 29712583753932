import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    Button,
    Card,
    Image,
    Grid,
    GridColumn,
    Header,
    TabPane,
} from 'semantic-ui-react';

import useFirestoreCollection from '../../../shared/firestore/hooks/useFirestoreCollection';
import { deleteFromFirebaseStorage } from '../service/profileFirebaseService';
import {
    deletePhotoFromCollection,
    getUserPhotos,
    setMainPhoto,
} from '../service/profileFirestoreService';
import { listenToUserPhotos } from '../store/profileActions';
import PhotoUploadWidget from './PhotoUploadWidget';

export default function PhotoTab({ profile, isCurrentUser }) {
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false);
    const { loading } = useSelector((state) => state.async);
    const { photos } = useSelector((state) => state.profile);
    const [updating, setUpdating] = useState({
        isUpdating: false,
        target: null,
    });
    const [deleting, setDeleting] = useState({
        isDeleting: false,
        target: null,
    });

    useFirestoreCollection({
        query: () => getUserPhotos(profile.id),
        data: (photos) => dispatch(listenToUserPhotos(photos)),
        deps: [profile.id, dispatch],
    });

    async function handleSetMainPhoto(photo, target) {
        setUpdating({
            isUpdating: true,
            target,
        });
        try {
            await setMainPhoto(photo);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setUpdating({
                isUpdating: false,
                target,
            });
        }
    }

    async function handleDeletePhoto(photo, target) {
        setDeleting({
            isDeleting: true,
            target,
        });
        try {
            await deleteFromFirebaseStorage(photo.name);
            await deletePhotoFromCollection(photo.id);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setDeleting({
                isDeleting: false,
                target,
            });
        }
    }

    return (
        <TabPane loading={loading}>
            <Grid>
                <GridColumn width={16}>
                    <Header floated="left" icon="user" content={`Profile`} />
                    {isCurrentUser && (
                        <Button
                            disabled={!isCurrentUser || photos.length >= 5}
                            onClick={() => setEditMode(!editMode)}
                            floated="right"
                            basic
                            content={editMode ? 'Cancel' : 'Add Photo'}
                        />
                    )}
                </GridColumn>
                <GridColumn width={16}>
                    {editMode ? (
                        <PhotoUploadWidget setEditMode={setEditMode} />
                    ) : (
                        <Card.Group itemsPerRow={5}>
                            {isCurrentUser &&
                                photos.map((photo) => (
                                    <Card key={photo.id}>
                                        <Image src={photo.url} />
                                        <Button.Group fluid widths={2}>
                                            <Button
                                                loading={
                                                    updating.isUpdating &&
                                                    updating.target === photo.id
                                                }
                                                name={photo.id}
                                                disabled={
                                                    photo.url ===
                                                    profile.photoURL
                                                }
                                                basic
                                                color="green"
                                                content="Main"
                                                onClick={(e) =>
                                                    handleSetMainPhoto(
                                                        photo,
                                                        e.target.name,
                                                    )
                                                }
                                            />
                                            <Button
                                                name={photo.id}
                                                loading={
                                                    deleting.isDeleting &&
                                                    deleting.target === photo.id
                                                }
                                                disabled={
                                                    photo.url ===
                                                    profile.photoURL
                                                }
                                                basic
                                                color="red"
                                                icon="trash"
                                                onClick={(e) =>
                                                    handleDeletePhoto(
                                                        photo,
                                                        e.target.name,
                                                    )
                                                }
                                            />
                                        </Button.Group>
                                    </Card>
                                ))}
                        </Card.Group>
                    )}
                </GridColumn>
            </Grid>
        </TabPane>
    );
}
