import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Modal } from 'semantic-ui-react';

import { openModal } from '../../modals/store/modalActions';

export default function UnauthModal({ history }) {
    const [open, setOpen] = useState(true);
    const { prevLocation } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    function handleClose() {
        if (history && prevLocation) {
            history.push(prevLocation.pathname);
        } else {
            history.push('/');
        }
        // setOpen(false);
    }

    return (
        <Modal open={open} size="mini" onClose={handleClose}>
            <Modal.Header content="You need to be signed in to do that" />
            <Modal.Content>
                <p> Please either login or register to see this content</p>
                <Button.Group widths={4}>
                    <Button
                        fluid
                        color="teal"
                        content="Login"
                        onClick={() =>
                            dispatch(
                                openModal({
                                    modalType: 'LoginForm',
                                }),
                            )
                        }
                    />
                    <Button.Or />
                    <Button
                        // disabled={true}
                        fluid
                        color="green"
                        content="Sign up"
                        onClick={() =>
                            dispatch(
                                openModal({
                                    modalType: 'RegisterForm',
                                }),
                            )
                        }
                    />
                </Button.Group>
                <Divider />
                <div style={{ textAlign: 'center' }}>
                    <p> Cancel to leave the page</p>
                    <Button onClick={handleClose} content="Cancel" />
                </div>
            </Modal.Content>
        </Modal>
    );
}
