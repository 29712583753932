import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';

import { Avatar, Tooltip } from 'antd';
import { format } from 'date-fns';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import IconButton from '@material-ui/core/IconButton';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import useFirestoreDoc from '../../../../shared/firestore/hooks/useFirestoreDoc';
import { listenToItineraries } from '../../service/firestoreService';
import { getUser } from '../../service/user';
import { listenBoardName } from '../../store/actions';
import {
    MenuActionButton,
    MenuButton,
    MoreButton,
    Nav,
    NavActionsWrapper,
    NavContainer,
} from '../../styled/boardNav_styled';
import { BackButton } from '../../styled/Boards';
import InviteUserModal from './InviteUserModal';
import IterinaryCategories, { ListOptions } from './IterinaryCategories';
import ItineraryAttendee from './ItineraryAttendee';
import ItineraryDateModal from './ItineraryDateModal';
import ItineraryTitleModal from './ItineraryTitleModal';
import MoreMenu from './MoreMenu';

function ItineraryMenu({ board, boardId, show, setShow }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isTitleModalOpen, setTitleModalOpen] = useState(false);
    const [isDateModalOpen, setDateModalOpen] = useState(false);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [isBoardMenuOpen, setIsBoardMenuOpen] = useState(false);
    const [attendees, setAttendees] = useState(null);
    const [host, setHost] = useState(false);
    const [endDate, setEndDate] = useState(0);

    useEffect(() => {
        // dispatch(loadUserBoards());
        dispatch(listenBoardName(boardId));
    }, [boardId, dispatch]);

    useFirestoreDoc({
        query: () => listenToItineraries(boardId),
        data: (itinerary) => {
            setAttendees(itinerary.attendees);
        },
        deps: [boardId],
    });

    function handleShowBoardMenu() {
        setIsBoardMenuOpen(!isBoardMenuOpen);
    }

    useEffect(() => {
        const user = getUser();
        setHost(user.uid === board.uid);

        if (typeof board.startDate === 'number') {
            setEndDate(board.startDate + (board.lists?.length - 1) * 86400000);
        }
    }, [board.uid, board.startDate, board.lists, attendees]);

    function getItineraryDate() {
        return board.lists.length - 1 > 0 ? (
            <>
                {format(board.startDate, 'MMMM d, yyyy')}
                {' - '}
                {format(endDate, 'MMMM d, yyyy')}
            </>
        ) : (
            <>Start Date: {format(board.startDate, 'MMMM d, yyyy')}</>
        );
    }

    return (
        <NavContainer>
            <Nav>
                <NavActionsWrapper>
                    <BackButton
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <Icon name="chevron left" color="black" />
                    </BackButton>
                    <MenuActionButton
                        onClick={() => {
                            setTitleModalOpen(true);
                        }}
                    >
                        {board.title}
                    </MenuActionButton>
                    <MenuButton
                        onClick={() => {
                            setIsInviteModalOpen(true);
                        }}
                    >
                        Invite
                    </MenuButton>
                    {isInviteModalOpen && (
                        <InviteUserModal
                            boardId={boardId}
                            open={isInviteModalOpen}
                            setOpen={setIsInviteModalOpen}
                        />
                    )}
                </NavActionsWrapper>

                <IterinaryCategories board={board} />

                <ListOptions className="ui icon" position="bottom center">
                    <Tooltip title="Show background image" placement="bottom">
                        <IconButton
                            size={'small'}
                            aria-label="map"
                            onClick={() => {
                                setShow(!show);
                            }}
                        >
                            <IndeterminateCheckBoxIcon fontSize={'small'} />
                        </IconButton>
                    </Tooltip>
                </ListOptions>

                <ItineraryTitleModal
                    boardId={boardId}
                    title={board.title}
                    open={isTitleModalOpen}
                    setOpen={setTitleModalOpen}
                />
            </Nav>

            <Avatar.Group
                maxCount={4}
                size="large"
                maxStyle={{
                    color: '#f56a00',
                    backgroundColor: '#fde3cf',
                }}
            >
                {attendees &&
                    attendees.map((attendee) => (
                        <ItineraryAttendee
                            key={attendee.id}
                            isHost={host}
                            boardId={boardId}
                            attendee={attendee}
                        />
                    ))}
            </Avatar.Group>

            <ItineraryDateModal
                date={board.startDate}
                open={isDateModalOpen}
                setOpen={setDateModalOpen}
            />

            <MoreMenu
                boardId={boardId}
                board={board}
                showBoardMenu={isBoardMenuOpen}
                setBoardMenu={setIsBoardMenuOpen}
            />

            <MoreButton>
                <MenuButton
                    onClick={() => {
                        setDateModalOpen(true);
                    }}
                >
                    {getItineraryDate()}
                </MenuButton>

                {/*{host && (*/}
                <MenuButton onClick={() => handleShowBoardMenu()}>
                    <Icon name="ellipsis horizontal" />
                </MenuButton>
                {/*)}*/}
            </MoreButton>
        </NavContainer>
    );
}

const mapStateToProps = (state) => ({
    board: state.board,
});

export default connect(mapStateToProps)(ItineraryMenu);
