import {
    ADD_ACTIVITY,
    FETCH_ACTIVITIES,
    RESET_ACTIVITIES,
    SET_FILTER,
    RETAIN_STATE,
    UPDATE_REV_CARD,
} from './bucketConstants';

const initialState = {
    activities: [],
    retainState: false,
    filter: 'all',
};

function updateRevCard(state, payload) {
    // console.log(state);
    // console.log(payload);

    const newActivities = state.activities.filter(
        (activity) => activity.id !== payload.rev.id,
    );

    // console.log([...newActivities, payload.rev]);

    return {
        ...state,
        activities: [
            ...state.activities.filter(
                (activity) => activity.id !== payload.rev.id,
            ),
            payload.rev,
        ],
    };
}

export default function bucketReducer(state = initialState, { type, payload }) {
    switch (type) {
        case RETAIN_STATE:
            return {
                ...state,
                retainState: true,
            };

        case FETCH_ACTIVITIES:
            return {
                ...state,
                activities: [...payload.activities],
                retainState: true,
            };
        case ADD_ACTIVITY:
            return {
                ...state,
                activities: [...state.activities, payload.activity],
            };
        case UPDATE_REV_CARD:
            updateRevCard(state, payload);

        case SET_FILTER:
            return {
                ...state,
                retainState: false,
                filter: payload,
            };

        case RESET_ACTIVITIES:
            return initialState;

        default:
            return state;
    }
}
