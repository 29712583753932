import { ObjectID } from 'bson';

import * as actionTypes from '../actions';

function newID() {
    return new ObjectID().toHexString();
}

const initialState = {
    boardId: '',
    title: '',
    archived: false,
    favorited: false,
    canUploadAttachment: false,
    startDate: new Date(),
    lists: [],
    selectedList: [],
    attachments: [],
};

function addList(state, action) {
    const newList = {
        title: 'Day ' + (state.lists.length + 1),
        cards: [],
        id: newID(),
    };
    if (state.lists) {
        return { ...state, lists: [...state.lists, newList] };
    } else {
        return { ...state, lists: [newList] };
    }
}

function updateList(state, action) {
    const updatedList = action.payload.updatedList;
    const listId = action.payload.listId;
    var newList = [];

    state.lists.map((list) => {
        if (list.id === listId) {
            newList = [...newList, updatedList];
        } else {
            newList = [...newList, list];
        }
    });

    return { ...state, lists: newList };
}

function deleteList(state, action) {
    const listId = action.payload.listId;
    const newLists = [...state.lists];

    state.lists.forEach(function (list, index) {
        if (list.id === listId) {
            newLists.splice(index, 1);
        }
    });

    return { ...state, lists: newLists };
}

function addCardToList(state, action) {
    const newCard = {
        text: action.payload.text,
        location: action.payload.location,
        id: newID(),
    };
    const newLists = state.lists.map((list) => {
        if (list.id === action.payload.listId) {
            if (list.cards) {
                return { ...list, cards: [...list.cards, newCard] };
            } else {
                return { ...list, cards: [newCard] };
            }
        } else {
            return list;
        }
    });

    if (
        !state.selectedList ||
        action.payload.listId !== state.selectedList.id
    ) {
        return { ...state, lists: newLists };
    }

    let selectedListUpdate = [];
    if (action.payload.listId === state.selectedList.id) {
        selectedListUpdate = newLists.filter(
            (list) => list.id === action.payload.listId,
        )[0];
    }

    return { ...state, lists: newLists, selectedList: selectedListUpdate };
}

function addAttachmentToBoard(state, action) {
    return { ...state };
}

function updateCardInList(state, action) {
    const updatedCard = action.payload.updatedCard;
    const newAttachments = action.payload.newAttachments;

    const newLists = state.lists.map((list) => {
        if (list.id === action.payload.listId) {
            for (let i = 0; i < list.cards.length; i++) {
                if (list.cards[i].id === updatedCard.id) {
                    list.cards[i] = {
                        ...updatedCard,
                        attachments: newAttachments,
                    };
                }
            }
            return { ...list };
        } else {
            return list;
        }
    });

    return {
        ...state,
        lists: newLists,
    };
}

function deleteCardFromList(state, action) {
    const cardID = action.payload.cardID;
    const listId = action.payload.listId;
    const newLists = state.lists.map((list) => {
        if (list.id === listId) {
            const cardsList = [...list.cards];
            list.cards.forEach(function (card, index) {
                if (card.id === cardID) {
                    cardsList.splice(index, 1);
                }
            });
            return { ...list, cards: cardsList };
        } else {
            return list;
        }
    });

    if (!state.selectedList || listId !== state.selectedList.id) {
        return { ...state, lists: newLists };
    }

    let selectedListUpdate = [];
    if (listId === state.selectedList.id) {
        selectedListUpdate = newLists.filter((list) => list.id === listId)[0];
    }

    return { ...state, lists: newLists, selectedList: selectedListUpdate };
}

function dragAndDrop(state, action) {
    const {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexStart,
        droppableIndexEnd,
        type,
    } = action.payload;
    const newState = { ...state };

    //dragging lists around
    if (type === 'list') {
        const list = newState.lists.splice(droppableIndexStart, 1);
        newState.lists.splice(droppableIndexEnd, 0, ...list);
        return newState;
    }

    //same list
    // need to make sure the selectId is not the same as the selectedList
    if (droppableIdStart === droppableIdEnd) {
        const list = newState.lists.find(
            (list) => droppableIdStart === list.id,
        );
        const card = list.cards.splice(droppableIndexStart, 1);
        list.cards.splice(droppableIndexEnd, 0, ...card);

        if (
            state.selectedList?.id &&
            droppableIdStart === state.selectedList.id
        ) {
            newState.selectedList = list;
        }
    }

    //different list
    if (droppableIdStart !== droppableIdEnd) {
        const list = newState.lists.find(
            (list) => droppableIdStart === list.id,
        );
        const otherList = newState.lists.find(
            (list) => droppableIdEnd === list.id,
        );
        const card = list.cards.splice(droppableIndexStart, 1);
        if (!otherList.cards) {
            otherList.cards = [...card];
        } else {
            otherList.cards.splice(droppableIndexEnd, 0, ...card);
        }

        if (droppableIdStart === state.selectedList.id) {
            newState.selectedList = list;
        } else if (droppableIdEnd === state.selectedList.id) {
            newState.selectedList = otherList;
        }
    }

    return newState;
}

function setTargetLocation(action, state) {
    const newLists = [...state.lists];

    state.lists.map((list, index) => {
        if (list.id === action.payload.listId) {
            newLists[index] = {
                ...newLists[index],
                targetLocation: action.payload.targetLocation,
            };
        }
    });

    return { ...state, lists: newLists };
}

export default function boardReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CREATE_BOARD_SUCCESS:
            return { ...state, boardId: action.id, lists: [] };
        case actionTypes.GET_BOARD_SUCCESS:
            return action.board;
        case actionTypes.GET_BOARD_FAIL:
            return {
                ...state,
                lists: [],
                boardId: action.uid,
            };
        case actionTypes.GET_LISTS:
            return state;

        case actionTypes.ADD_LIST:
            return addList(state, action);

        case actionTypes.UPDATE_LIST:
            return updateList(state, action);

        case actionTypes.DELETE_LIST:
            return deleteList(state, action);

        case actionTypes.ADD_CARD: {
            return addCardToList(state, action);
        }

        case actionTypes.UPDATE_CARD: {
            return updateCardInList(state, action);
        }

        case actionTypes.DELETE_CARD: {
            return deleteCardFromList(state, action);
        }

        case actionTypes.ADD_ATTACHMENT: {
            return addAttachmentToBoard(state, action);
        }

        case actionTypes.ARCHIVED_BOARD:
            return { ...state, archived: true };

        case actionTypes.UNARCHIVED_BOARD:
            return { ...state, archived: false };

        case actionTypes.FAVORITED_BOARD:
            return { ...state, favorited: true };

        case actionTypes.UNFAVORITED_BOARD:
            return { ...state, favorited: false };

        case actionTypes.UPDATE_ITINERARY_DATE:
            return { ...state, startDate: action.payload.date };

        case actionTypes.DRAG_HAPPENED:
            return dragAndDrop(state, action);

        case actionTypes.UPDATE_BOARD_TITLE:
            return { ...state, title: action.title };

        case actionTypes.SET_TARGET_LOCATION: {
            return setTargetLocation(action, state);
        }

        case actionTypes.SET_SELECTED_LIST: {
            return {
                ...state,
                selectedList: {
                    ...action.payload.selectedList,
                    day: action.payload.day,
                },
            };
        }

        case actionTypes.RESET_BOARD:
            return initialState;

        default:
            return state;
    }
}
