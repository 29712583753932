/* global google */
import React, { useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';

import CustomTextArea from '../../../../../shared/common/formFields/CustomTextArea';
import CustomTextInput from '../../../../../shared/common/formFields/CustomTextInput';
import useFirestoreDoc from '../../../../../shared/firestore/hooks/useFirestoreDoc';
import { uploadToStorage } from '../../../service/firebaseService';
import { listenToItineraries } from '../../../service/firestoreService';
import { updateCardFromBoard } from '../../../store/actions';
import ActivityAttachmentsForm from './ActivityAttachmentsForm';
import ActivityAttendeeForm from './ActivityAttendeeForm';
import ActivityCategoriesForm from './ActivityCategoriesForm';
import ActivityLocationForm from './ActivityLocationForm';
import ActivityTimePickerForm from './ActivityTimePickerForm';

export default function ActivityModal({
    targetLocation,
    boardId,
    listId,
    card,
    open,
    setOpen,
}) {
    const dispatch = useDispatch();
    const { canUploadAttachment } = useSelector((state) => state.board);
    const [oldAttachments, setOldAttachments] = useState([]);
    const [attendees, setAttendees] = useState(null);

    useFirestoreDoc({
        query: () => listenToItineraries(boardId),
        data: (itinerary) => {
            setAttendees(itinerary.attendees);
        },
        deps: [boardId],
    });

    const initialFormValue = card ?? {
        title: '',
        location: {
            address: '',
            latLng: null,
        },
        time: new Date('2014-08-18T21:11:54'),
        description: '',
        checked: [],
        attachments: [],
        cost: '',
        attended: [],
    };

    useEffect(() => {
        setOldAttachments(card.attachments || []);
    }, [card]);

    function openMap(location) {
        if (window.confirm('Open in Google maps?')) {
            window.open('https://www.google.com/maps/dir/' + location);
        }
    }

    /**
     *  if we are adding a new attachment we will need to upload the attachment
     *  to cloud storage first, and then update the card. Otherwise we will
     *  pass the oldAttachments and do our normal update.
     *
     *  Case 1: no attachment -> regular update
     *  Case 2: has attachment but not new attachment -> regular update
     *  Case 3: new attachment -> need to upload the pic and then update
     **/
    function handleSubmit(values, setSubmitting) {
        //values.attachments[0].path &&
        if (
            values.attachments &&
            values.attachments.length > 0 &&
            values.attachments[0].path
        ) {
            try {
                uploadToStorage(dispatch, listId, oldAttachments, values);
                setSubmitting(false);
            } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
            } finally {
                setOpen(false);
            }
        } else {
            try {
                dispatch(updateCardFromBoard(listId, oldAttachments, values));
                setSubmitting(false);
            } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
            } finally {
                setOpen(false);
            }
        }
    }

    return (
        <Modal open={open} size="small" onClose={() => setOpen(false)}>
            <Modal.Content>
                <Formik
                    initialValues={initialFormValue}
                    onSubmit={async (values, { setSubmitting }) => {
                        handleSubmit(values, setSubmitting);
                    }}
                >
                    {({
                        isSubmitting,
                        isValid,
                        dirty,
                        setFieldValue,
                        values,
                    }) => (
                        <Form className="ui form">
                            <label>Activity Name</label>
                            <CustomTextInput
                                name="text"
                                placeholder="Check in at Disneyland*"
                                autoComplete="off"
                            />
                            {ActivityLocationForm(
                                values,
                                openMap,
                                targetLocation,
                            )}
                            <label>Activity Time</label>
                            {ActivityTimePickerForm(values, setFieldValue)}
                            <br />
                            <label>Activity cost</label>
                            <CustomTextInput
                                name="cost"
                                placeholder="$10"
                                autoComplete="off"
                                type="number"
                            />
                            <label>Who attended?</label>
                            {ActivityAttendeeForm(
                                values,
                                setFieldValue,
                                attendees,
                            )}
                            <br />
                            <label>Additional description</label>
                            <CustomTextArea
                                name="description"
                                placeholder="You can add any additional information here"
                                autoComplete="off"
                            />
                            <label>Category</label>
                            {ActivityCategoriesForm(values, setFieldValue)}
                            {ActivityAttachmentsForm(
                                values,
                                canUploadAttachment,
                                setFieldValue,
                                oldAttachments,
                                listId,
                            )}
                            <br />
                            <Button
                                loading={isSubmitting}
                                disabled={!isValid || !dirty || isSubmitting}
                                type="submit"
                                fluid
                                size="small"
                                color="teal"
                                content="Update"
                            />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
        </Modal>
    );
}
