import React, { useEffect, useState } from 'react';

import useFirestoreDoc from '../../../../shared/firestore/hooks/useFirestoreDoc';
import { calculateCostPerPerson } from '../../service/boardService';
import { getUserNameById } from '../../service/firestoreService';

export default function ItineraryAttendeeCostView({ board, card }) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userName, setUserName] = useState(null);
    const [userCost, setUserCost] = useState(null);

    const totalCostToAttendeeMap = new Map();

    useEffect(() => {
        if (board.attendees) {
            Object.keys(board.attendees).forEach((attendee) => {
                totalCostToAttendeeMap.set(attendee, 0);
            });
        }
    }, [board.attendees, totalCostToAttendeeMap]);

    useEffect(() => {
        calculateCostPerPerson(board, totalCostToAttendeeMap);
        setUserCost(totalCostToAttendeeMap.get(card.id));
    }, [board, card.id, totalCostToAttendeeMap]);

    useFirestoreDoc({
        query: () => getUserNameById(card.id),
        data: (user) => {
            setUserName(user.displayName);
            setIsLoaded(true);
        },
        deps: [card.id],
    });
    return (
        <>
            {!isLoaded && <p>loading...</p>}
            {isLoaded && (
                <div>
                    {userName} :{' '}
                    {userCost && userCost.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </div>
            )}
        </>
    );
}
