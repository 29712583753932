import React, { useState } from 'react';

import { useField } from 'formik';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { FormField, Label, Segment, List } from 'semantic-ui-react';

const CustomPlaceInput = ({ label, options, ...props }) => {
    const [geocoding, setGeocoding] = useState(null);
    const [field, meta, helpers] = useField(props);

    const handleSelect = (address) => {
        var countryLongName,
            countryShortName,
            adminAreaLongName,
            adminAreaShortName;

        geocodeByAddress(address)
            .then((results) => {
                const geocoding = results[0];

                geocoding.address_components.forEach((address) => {
                    if (address.types.includes('country')) {
                        countryLongName = address.long_name;
                        countryShortName = address.short_name;
                    }
                    if (address.types.includes('administrative_area_level_1')) {
                        adminAreaLongName = address.long_name;
                        adminAreaShortName = address.short_name;
                    }
                });
                return getLatLng(results[0]);
            })
            .then((latLng) => {
                // console.log(countryLongName);
                // console.log(countryShortName);

                helpers.setValue({
                    countryLongName,
                    countryShortName,
                    adminAreaLongName,
                    adminAreaShortName,
                    address,
                    latLng,
                });
            })
            .catch((error) => helpers.setError(error));
    };

    const handleBlur = (e) => {
        field.onBlur(e);
        if (!field.value.latLng) {
            helpers.setValue({ address: '', latLng: null });
        }
    };

    return (
        <PlacesAutocomplete
            value={field.value['address']}
            onChange={(value) => helpers.setValue({ address: value })}
            onSelect={(value) => handleSelect(value)}
            searchOptions={options}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }) => (
                <FormField error={meta.touched && !!meta.error}>
                    <input
                        {...getInputProps({
                            name: field.name,
                            onBlur: (e) => handleBlur(e),
                            ...props,
                        })}
                    />
                    {meta.touched && meta.error ? (
                        <Label basic color="red">
                            {meta.error['address']}
                        </Label>
                    ) : null}

                    {suggestions?.length > 0 && (
                        <Segment
                            loading={loading}
                            style={{
                                marginTop: 0,
                                position: 'absolute',
                                zIndex: 1000,
                                width: '100%',
                            }}
                        >
                            <List selection>
                                {suggestions.map((suggestion, index) => (
                                    <List.Item
                                        key={index}
                                        {...getSuggestionItemProps(suggestion)}
                                    >
                                        <List.Header>
                                            {
                                                suggestion.formattedSuggestion
                                                    .mainText
                                            }
                                        </List.Header>
                                        <List.Description>
                                            {
                                                suggestion.formattedSuggestion
                                                    .secondaryText
                                            }
                                        </List.Description>
                                    </List.Item>
                                ))}
                            </List>
                        </Segment>
                    )}
                </FormField>
            )}
        </PlacesAutocomplete>
    );
};

export default CustomPlaceInput;
