import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';

export default function ActivityTimePickerForm(values, setFieldValue) {
    const [selectedDate, _] = React.useState(new Date('2014-08-18T00:00:00'));

    const handleDateChange = (date) => {
        setFieldValue('time', date);
    };

    const getValue = () => {
        if (values.time) {
            return values.time;
        }

        return selectedDate;
    };

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    value={getValue()}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}
