import React, { useState } from 'react';

import moment from 'moment';
import { DateRange } from 'react-date-range';

import {
    TextField,
    Popover,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const ListDateRangePicker = () => {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const [displayCalendar, setDisplayCalendar] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState('');
    //   dateFormat = 'MM/DD/YYYY';
    //
    //   state = {
    //       displayCalendar: false,
    //       inputValue: '',
    //       anchorEl: null,
    //       fromDate: undefined,
    //       toDate: undefined,
    //   };
    //
    const onAdornmentClick = (e) => {
        setDisplayCalendar(true);
        setAnchorEl(e.currentTarget);
    };

    const onInputChange = (e) => {
        console.log(e.target.value);
        // const inputValue = e.target.value;
        // const { fromDate, toDate } = this.processInputValue(inputValue);
        //
        // this.setState({ inputValue, fromDate, toDate });
    };

    const onPopoverClose = (e, reason) => {
        setDisplayCalendar(false);
        setAnchorEl(null);
    };
    //
    //   const  onSelectDateRanges = ({ selection }) => {
    //       let { startDate, endDate } = selection;
    //
    //       startDate = moment(startDate);
    //       startDate = startDate.isValid() ? startDate.toDate() : undefined;
    //
    //       endDate = moment(endDate);
    //       endDate = endDate.isValid() ? endDate.toDate() : undefined;
    //
    //       let inputValue = '';
    //       if (startDate) inputValue += moment(startDate).format(this.dateFormat);
    //       if (endDate)
    //           inputValue += ' - ' + moment(endDate).format(this.dateFormat);
    //
    //       this.setState({ fromDate: startDate, toDate: endDate, inputValue });
    //   };
    //
    //   const   processInputValue(value) {
    //       let [fromDate, toDate] = value.split('-').map((elm) => elm.trim());
    //
    //       fromDate = moment(fromDate, this.dateFormat);
    //       fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;
    //
    //       toDate = moment(toDate, this.dateFormat);
    //       toDate = toDate.isValid() ? toDate.toDate() : undefined;
    //
    //       return { fromDate, toDate };
    //   }

    function updateDateRange(item) {
        console.log(item.selection);
        setState([item.selection]);
        console.log(state);
    }

    return (
        <div>
            <TextField
                label="Date Time Picker"
                value={inputValue}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onAdornmentClick}>
                                <DateRangeIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onChange={onInputChange}
            />
            <Popover
                open={displayCalendar}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={onPopoverClose}
            >
                <div>
                    <DateRange
                        ranges={state}
                        onChange={(item) => updateDateRange(item)}
                        showMonthAndYearPickers={true}
                        moveRangeOnFirstSelection={false}
                        showDateDisplay={false}
                        scroll={{ enabled: true }}
                    />
                </div>
            </Popover>
        </div>
    );
};

export default ListDateRangePicker;
