import styled from 'styled-components';

export const StyledCardContent = styled.div`
    padding: 0 0 0 12px;
    //display: inline-block;
    width: 80%;
    display: flex;
`;
export const CardOptions = styled.div`
    visibility: hidden;
    color: #172b4d;
    opacity: 0.7;
`;

export const StyledCard = styled.div`
    word-wrap: break-word;
    border: 1px solid lightgrey;
    border-radius: 0.3rem;
    padding: 2px;
    margin-bottom: 2px;

    justify-content: space-between;
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 10%;

    &:hover {
        background-color: rgb(240, 240, 240);
    }
    &:hover ${CardOptions} {
        visibility: visible;
    }
`;

export const ActivityTitle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    font-size: 16px;
    //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    //    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    //color: #172b4d;
`;

export const ActivityLocation = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    font-style: italic;
    font-size: 12px;
    //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    //    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    //color: #172b4d;
`;

export const Tile = styled.div`
    position: relative;
    display: inline-block;
    padding: 10px;
    width: 20%;
    height: 50px;
    background-color: #006ba3;
    color: white;
    margin: 0.3%;
    border-radius: 0.3rem;
    transition: all 0.3s;
    background-image: ${(props) => 'url(' + props.theme + ')'};
    background-size: cover;
    background-position: 50%;

    &:hover {
        background-color: #0e566c;
        opacity: 0.75;
    }

    @media only screen and (max-width: 400px) and (min-width: 82px) {
        width: 96%;
        margin: 2%;
    }
    @media only screen and (max-width: 720px) and (min-width: 400px) {
        width: 45%;
    }
    @media only screen and (max-width: 1024px) and (min-width: 720px) {
        width: 30%;
    }
    @media only screen and (max-width: 1480px) and (min-width: 1024px) {
        width: 15%;
    }

    &:hover ${CardOptions} {
        visibility: visible;
    }
`;

export const TileText = styled.div`
    text-align: left;
    width: 80%;
    color: #e2f5fe;
    position: relative;
    font-weight: bold;
    margin-left: 8px;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
