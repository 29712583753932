import { db } from '../../service/firebaseService';
import { getUser } from '../../service/user';
import {
    ADD_ATTACHMENT,
    ADD_CARD,
    ADD_LIST,
    ARCHIVED_BOARD,
    DELETE_CARD,
    DELETE_LIST,
    DRAG_HAPPENED,
    FAVORITED_BOARD,
    GET_BOARD_FAIL,
    GET_BOARD_REQUEST,
    GET_BOARD_SUCCESS,
    SET_SELECTED_LIST,
    SET_TARGET_LOCATION,
    UNARCHIVED_BOARD,
    UNFAVORITED_BOARD,
    UPDATE_BOARD_FAIL,
    UPDATE_BOARD_REQUEST,
    UPDATE_BOARD_SUCCESS,
    UPDATE_CARD,
    UPDATE_LIST,
    UPDATE_ITINERARY_DATE,
} from './boardConstants';

export const addList = () => {
    return {
        type: ADD_LIST,
    };
};

export const deleteList = (listId) => {
    return {
        type: DELETE_LIST,
        payload: { listId },
    };
};

export const addCard = (listId, { text, location }) => {
    return {
        type: ADD_CARD,
        payload: { text, location, listId },
    };
};

export const updateCard = (listId, newAttachments, updatedCard) => {
    return {
        type: UPDATE_CARD,
        payload: { listId, newAttachments, updatedCard },
    };
};

export const updateList = (listId, updatedList) => {
    return {
        type: UPDATE_LIST,
        payload: { listId, updatedList },
    };
};

export const deleteCard = (cardID, listId) => {
    return {
        type: DELETE_CARD,
        payload: { cardID, listId },
    };
};

export const addAttachment = (listId, file, values) => {
    return {
        type: ADD_ATTACHMENT,
        payload: { file, values, listId },
    };
};

export const requestBoard = () => {
    return {
        type: GET_BOARD_REQUEST,
    };
};

export const receiveBoard = (board) => {
    return {
        type: GET_BOARD_SUCCESS,
        board,
    };
};

export const receiveBoardError = (uid) => {
    return {
        type: GET_BOARD_FAIL,
        uid,
    };
};

export const requestUpdateBoard = () => {
    return {
        type: UPDATE_BOARD_REQUEST,
    };
};

export const receiveUpdatedBoard = () => {
    return {
        type: UPDATE_BOARD_SUCCESS,
    };
};

export const updateBoardError = () => {
    return {
        type: UPDATE_BOARD_FAIL,
    };
};

export const dragAndDrop =
    (
        droppableIdStart,
        droppableIdEnd,
        droppableIndexStart,
        droppableIndexEnd,
        type,
    ) =>
    (dispatch, getState) => {
        dispatch(
            sort(
                droppableIdStart,
                droppableIdEnd,
                droppableIndexStart,
                droppableIndexEnd,
                type,
            ),
        );

        dispatch(updateBoard(getState().board));
    };

export const sort = (
    droppableIdStart,
    droppableIdEnd,
    droppableIndexStart,
    droppableIndexEnd,
    type,
) => {
    return {
        type: DRAG_HAPPENED,
        payload: {
            droppableIdStart,
            droppableIdEnd,
            droppableIndexStart,
            droppableIndexEnd,
            type,
        },
    };
};

export const addCardToBoard = (listId, values) => (dispatch, getState) => {
    dispatch(addCard(listId, values));
    dispatch(updateBoard(getState().board));
};

export const addCardToExactBoard = (listId, boardId, values) => (dispatch, getState) => {
    dispatch(addCard(listId, values));
    dispatch(updateExactBoard(getState().board, boardId));
};

export const updateListInBoard = (listId, values) => (dispatch, getState) => {
    dispatch(updateList(listId, values));
    dispatch(updateBoard(getState().board));
};

export const updateCardFromBoard =
    (listId, newAttachments, values) => (dispatch, getState) => {
        dispatch(updateCard(listId, newAttachments, values));
        dispatch(updateBoard(getState().board));
    };

export const deleteCardFromBoard = (cardID, listId) => (dispatch, getState) => {
    dispatch(deleteCard(cardID, listId));
    dispatch(updateBoard(getState().board));
};

export const addListToBoard = () => (dispatch, getState) => {
    dispatch(addList());
    dispatch(updateBoard(getState().board));
};

export const deleteListFromBoard = (listId) => (dispatch, getState) => {
    dispatch(deleteList(listId));
    dispatch(updateBoard(getState().board));
};

export const archiveBoard = () => (dispatch, getState) => {
    dispatch({
        type: ARCHIVED_BOARD,
    });
    dispatch(updateBoardAndBoards(getState().board));
};

export const unArchiveBoard = () => (dispatch, getState) => {
    dispatch({
        type: UNARCHIVED_BOARD,
    });
    dispatch(updateBoardAndBoards(getState().board));
};

export const favoriteBoard = () => (dispatch, getState) => {
    dispatch({
        type: FAVORITED_BOARD,
    });
    dispatch(updateBoardAndBoards(getState().board));
};

export const unfavoriteBoard = () => (dispatch, getState) => {
    dispatch({
        type: UNFAVORITED_BOARD,
    });
    dispatch(updateBoardAndBoards(getState().board));
};

export const addAttachmentToBoard =
    (listId, file, values) => (dispatch, getState) => {
        dispatch(addAttachment(listId, file, values));
        dispatch(updateBoard(getState().board));
    };

export const updateBoardAndBoards = (board) => (dispatch) => {
    const user = getUser();
    if (!user) {
        dispatch(updateBoardError());
    } else {
        dispatch(requestUpdateBoard());
        db.ref('board')
            .child(board.id)
            .update(board)
            .then(() => {
                dispatch(receiveUpdatedBoard());
            })
            .catch((err) => {
                dispatch(updateBoardError());
            });

        const { favorited, archived } = board;

        db.ref('boards')
            .child(board.id)
            .update({ archived, favorited })
            .then(() => {
                dispatch(receiveUpdatedBoard());
            })
            .catch((err) => {
                dispatch(updateBoardError());
            });
    }
};

export const updateItineraryDate = (date) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_ITINERARY_DATE,
        payload: { date },
    });
    dispatch(updateBoard(getState().board));
};

export const updateBackGroundForBoard = (boardId, url) => (dispatch) => {
    dispatch(requestUpdateBoard());
    db.ref('board')
        .child(boardId)
        .update({ theme: url })
        .then(() => {
            dispatch(receiveUpdatedBoard());
        })
        .catch((err) => {
            dispatch(updateBoardError());
        });

    db.ref('boards')
        .child(boardId)
        .update({ theme: url })
        .then(() => {
            dispatch(receiveUpdatedBoard());
        })
        .catch((err) => {
            dispatch(updateBoardError());
        });
};

export const updateBoard = (board) => (dispatch) => {
    const user = getUser();
    if (!user) {
        dispatch(updateBoardError());
    } else {
        dispatch(requestUpdateBoard());
        db.ref('board')
            .child(board.id)
            .update(board)
            .then(() => {
                dispatch(receiveUpdatedBoard());
            })
            .catch((err) => {
                dispatch(updateBoardError());
            });
    }
};

export const updateExactBoard = (board, boardID) => (dispatch) => {
    const user = getUser();
    if (!user) {
        dispatch(updateBoardError());
    } else {
        dispatch(requestUpdateBoard());
        db.ref('board')
            .child(boardID)
            .update(board)
            .then(() => {
                dispatch(receiveUpdatedBoard());
            })
            .catch((err) => {
                dispatch(updateBoardError());
            });
    }
};

export const loadBoard = (uid) => (dispatch) => {
    dispatch(requestBoard());
    const user = getUser();

    let board = {};
    db.ref('board')
        .child(uid)
        .once('value', (snapshot) => {
            const attendees = snapshot.val().attendees;

            if (attendees[user.uid]) {
                board = {
                    id: snapshot.val().id,
                    uid: snapshot.val().uid,
                    title: snapshot.val().title,
                    archived: snapshot.val().archived,
                    favorited: snapshot.val().favorited,
                    canUploadAttachment:
                        snapshot.val().canUploadAttachment || false,
                    lists: snapshot.val().lists,
                    startDate: snapshot.val().startDate,
                    attendees: snapshot.val().attendees,
                    selectedList: snapshot.val().selectedList,
                    theme: snapshot.val().theme,
                    attachments: snapshot.val().attachments || [],
                };
            }
        })
        .then(() => {
            dispatch(receiveBoard(board));
        })
        .catch((err) => {
            dispatch(receiveBoardError(uid));
        });
};

export const listenBoard = (uid) => (dispatch) => {
    dispatch(requestBoard());
    const user = getUser();

    db.ref('/board/' + uid).on('value', function (snapshot) {
        const attendees = snapshot.val().attendees;

        if (snapshot.val() != null && attendees[user.uid]) {
            const board = {
                id: snapshot.val().id,
                uid: snapshot.val().uid,
                title: snapshot.val().title,
                archived: snapshot.val().archived,
                favorited: snapshot.val().favorited,
                canUploadAttachment:
                    snapshot.val().canUploadAttachment || false,
                lists: snapshot.val().lists,
                startDate: snapshot.val().startDate,
                attendees: snapshot.val().attendees,
                selectedList: snapshot.val().selectedList,
                theme: snapshot.val().theme,
                attachments: snapshot.val().attachments || [],
                enableEditListName: snapshot.val().enableEditListName || false,
            };
            dispatch(receiveBoard(board));
        }
    });
};

export const addTargetLocation = (listId, { targetLocation }) => {
    return {
        type: SET_TARGET_LOCATION,
        payload: { targetLocation, listId },
    };
};

export const updateListTargetLocation =
    (listId, targetLocation) => (dispatch, getState) => {
        dispatch(addTargetLocation(listId, targetLocation));
        dispatch(updateBoard(getState().board));
    };

export const setSelectedListAction = (selectedList, day) => {
    return {
        type: SET_SELECTED_LIST,
        payload: { selectedList, day },
    };
};

export const setSelectedList = (selectedList, day) => (dispatch, getState) => {
    dispatch(setSelectedListAction(selectedList, day));
    dispatch(updateBoard(getState().board));
};
