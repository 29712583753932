import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridColumn, Header, TabPane, Card } from 'semantic-ui-react';

import useFirestoreCollection from '../../../shared/firestore/hooks/useFirestoreCollection';
import ProfileCard from '../ProfileCard';
import {
    getFollowersCollection,
    getFollowingCollection,
} from '../service/profileFirestoreService';
import { listenToFollowers, listenToFollowing } from '../store/profileActions';

export default function FollowingTab({ profile, activeTab }) {
    const dispatch = useDispatch();
    const { following, followers } = useSelector((state) => state.profile);

    useFirestoreCollection({
        query:
            activeTab === 2
                ? () => getFollowersCollection(profile.id)
                : () => getFollowingCollection(profile.id),
        data: (data) =>
            activeTab === 2
                ? dispatch(listenToFollowers(data))
                : dispatch(listenToFollowing(data)),
        deps: [dispatch, activeTab, profile.id],
    });

    return (
        <TabPane>
            <Grid>
                <GridColumn width={16}>
                    <Header
                        floated="left"
                        icon="user"
                        content={activeTab === 2 ? 'Followers' : 'Following'}
                    />
                </GridColumn>
                <GridColumn width={16}>
                    <Card.Group itemsPerRow={5}>
                        {activeTab === 2 &&
                            followers.map((profile) => (
                                <ProfileCard
                                    profile={profile}
                                    key={profile.id}
                                />
                            ))}
                        {activeTab === 3 &&
                            following.map((profile) => (
                                <ProfileCard
                                    profile={profile}
                                    key={profile.id}
                                />
                            ))}
                    </Card.Group>
                </GridColumn>
            </Grid>
        </TabPane>
    );
}
