import React, { useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import { getUser } from '../../service/user';
import {
    archiveBoard,
    favoriteBoard,
    unArchiveBoard,
    unfavoriteBoard,
} from '../../store/actions';

export const ListOptions = styled.div`
    //display: inline-block;
    //border-radius: 0.3rem;
    padding: 0.4rem;
    margin-right: 5px;
    background-color: #6f93ad !important;
    color: white !important;
    opacity: 0.7;
    float: right;

    &:hover {
        background-color: #90acc0 !important;
    }
`;

export default function IterinaryCategories({ board }) {
    const dispatch = useDispatch();
    const [host, setHost] = useState(false);

    function handleArchive() {
        dispatch(archiveBoard());
    }
    function handleUnArchive() {
        dispatch(unArchiveBoard());
    }
    function handleFavorite() {
        dispatch(favoriteBoard());
    }
    function handleUnFavorite() {
        dispatch(unfavoriteBoard());
    }

    useEffect(() => {
        const user = getUser();
        setHost(user.uid === board.uid);
    }, [board.uid]);

    return (
        <>
            {host &&
                (board.favorited ? (
                    <ListOptions className="ui icon">
                        <Tooltip title="Unfavorite" placement="bottom">
                            <IconButton
                                size={'small'}
                                aria-label="map"
                                onClick={() => {
                                    handleUnFavorite();
                                }}
                            >
                                <FavoriteIcon
                                    fontSize={'small'}
                                    style={{ color: 'red' }}
                                />
                            </IconButton>
                        </Tooltip>
                    </ListOptions>
                ) : (
                    <ListOptions className="ui icon">
                        <Tooltip title="Favorite" placement="bottom">
                            <IconButton
                                size={'small'}
                                aria-label="map"
                                onClick={() => {
                                    handleFavorite();
                                }}
                            >
                                <FavoriteBorderIcon fontSize={'small'} />
                            </IconButton>
                        </Tooltip>
                    </ListOptions>
                ))}
            {host &&
                (board.archived ? (
                    <ListOptions className="ui icon">
                        <Tooltip title="Unarchive" placement="bottom">
                            <IconButton
                                size={'small'}
                                aria-label="map"
                                onClick={() => {
                                    handleUnArchive();
                                }}
                            >
                                <FolderIcon fontSize={'small'} />
                            </IconButton>
                        </Tooltip>
                    </ListOptions>
                ) : (
                    <ListOptions className="ui icon" position="bottom center">
                        <Tooltip title="Archive" placement="bottom">
                            <IconButton
                                size={'small'}
                                aria-label="map"
                                onClick={() => {
                                    handleArchive();
                                }}
                            >
                                <FolderOpenIcon fontSize={'small'} />
                            </IconButton>
                        </Tooltip>
                    </ListOptions>
                ))}
        </>
    );
}
