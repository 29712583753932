import React from 'react';

import { useSelector } from 'react-redux';

import TestModal from '../../../domain/sandbox/TestModal';
import LoginForm from '../registration/auth/LoginForm';
import RegisterForm from '../registration/auth/RegisterForm';
import ResetPasswordForm from '../registration/auth/ResetPasswordForm';

const ModalManager = () => {
    const modalLookup = {
        TestModal,
        LoginForm,
        RegisterForm,
        ResetPasswordForm,
    };
    const currentModal = useSelector((state) => state.modals);

    let renderedModal;
    if (currentModal) {
        const { modalType, modalProps } = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />;
    }

    return <span>{renderedModal}</span>;
};

export default ModalManager;
