import { toJson } from 'unsplash-js';

import unsplash from '../unsplash/unsplash';

export const CHANGE_BACKGROUND = 'CHANGE_BACKGROUND';

export const CHANGE_PHOTO_REQUEST = 'CHANGE_PHOTO_REQUEST';
export const CHANGE_PHOTO_SUCCESS = 'CHANGE_PHOTO_SUCCESS';
export const CHANGE_PHOTO_ERROR = 'CHANGE_PHOTO_ERROR';

export const GET_PHOTOS_REQUEST = 'GET_PHOTOS_REQUEST';
export const GET_PHOTOS_SUCCESS = 'GET_PHOTOS_SUCCESS';
export const GET_PHOTOS_ERROR = 'GET_PHOTOS_ERROR';

export const RESET_THEME = 'RESET_THEME';

export const getPhotos = (payload) => ({
    type: GET_PHOTOS_SUCCESS,
    payload,
});

export const getPhotosList = (keyword) => (dispatch) => {
    // dispatch(getPhotos(null));
    unsplash.search
        .photos(keyword, 1, 0, { orientation: 'landscape' })
        .then(toJson)
        .then((json) => {
            dispatch(getPhotos(json.results));
        })
        .catch((error) => {
            // dispatch(getPhotos(null, GET_PHOTOS_ERROR));
            console.log(error);
        });
};
