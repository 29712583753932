import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import {
    asyncActionError,
    asyncActionFinish,
    asyncActionStart,
} from '../../common/async/store/asyncActions';
import { dataFromSnapshot } from '../firestoreService';

/**
 * Returns the snapshot to a firebase collection.
 *
 *
 * */
export default function useFirestoreCollection({ query, data, deps }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(asyncActionStart());
        const unsubscribe = query().onSnapshot(
            (snapshot) => {
                const docs = snapshot.docs.map((doc) => dataFromSnapshot(doc));
                data(docs);
                dispatch(asyncActionFinish());
            },

            (error) => dispatch(asyncActionError(error)),
        );
        return () => {
            unsubscribe();
        };
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
