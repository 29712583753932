import React, { useCallback, useEffect, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

const Thumbnail = styled.aside`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
`;

export default function ActivityAttachmentDropZone({ setFieldValue }) {
    const [files, setFiles] = useState([]);

    const maxSize = 1048576;

    const dropzoneStyles = {
        border: 'dashed 3px #eee',
        borderRadius: '5%',
        paddingTop: '10px',
        textAlign: 'center',
    };

    const dropzoneActive = {
        border: 'dashed 3px green',
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );

            setFieldValue(
                'attachments',
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
        [setFieldValue],
    );

    const { getRootProps, getInputProps, isDragActive, fileRejections } =
        useDropzone({
            accept: '.jpeg,.jpg,.png,.gif,.pdf',
            onDrop,
            maxSize,
        });

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ));

    const isFileTooLarge =
        fileRejections.length > 0 && fileRejections[0].size > maxSize;

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files],
    );

    return (
        <section className="container">
            <div
                {...getRootProps()}
                style={
                    isDragActive
                        ? { ...dropzoneStyles, ...dropzoneActive }
                        : dropzoneStyles
                }
            >
                <input {...getInputProps()} />
                {isFileTooLarge && (
                    <div className="text-danger mt-2">File is too large.</div>
                )}
                <Icon name="upload" size="small" />
                <Header content="Drop attachment here" />
            </div>
            <Thumbnail>{thumbs}</Thumbnail>
        </section>
    );
}
