import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    Grid,
    GridColumn,
    ItemGroup,
    Segment,
    Item,
    ItemImage,
    ItemContent,
    StatisticGroup,
    Statistic,
    Divider,
    RevealContent,
    Reveal,
    Header,
    Button,
} from 'semantic-ui-react';

import { sleep } from '../../shared/utils/utills';
import {
    followUser,
    getFollowingDoc,
    unfollowUser,
} from './service/profileFirestoreService';
import {
    clearFollowings,
    setFollowUser,
    setUnfollowUser,
} from './store/profileActions';

export default function ProfileHeader({ profile, isCurrentUser }) {
    const dispatch = useDispatch();
    const { followingUser } = useSelector((state) => state.profile);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isCurrentUser) return;
        setLoading(true);

        async function fetchFollowingDoc() {
            try {
                const followingDoc = await getFollowingDoc(profile.id);
                if (followingDoc && followingDoc.exists) {
                    dispatch(setFollowUser());
                }
            } catch (error) {
                toast.error(error.message);
            }
        }

        fetchFollowingDoc().then(() => setLoading(false));
        return () => {
            dispatch(clearFollowings());
        };
    }, [dispatch, profile.id, isCurrentUser]);

    async function handleFollowUser() {
        setLoading(true);
        try {
            await followUser(profile);
            await sleep(2000);
            dispatch(setFollowUser());
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    async function handleUnfollowUser() {
        setLoading(true);
        try {
            await unfollowUser(profile);
            await sleep(2000);
            dispatch(setUnfollowUser());
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Segment>
            <Grid>
                <GridColumn width={12}>
                    <ItemGroup>
                        <Item>
                            <ItemImage
                                avatar
                                size="small"
                                src={profile.photoURL || '/assets/user.png'}
                                // style={{ maxWidth: '150px' }}
                            />
                            <ItemContent verticalAlign="middle">
                                <Header
                                    as="h1"
                                    style={{
                                        display: 'block',
                                        marginBottom: 10,
                                    }}
                                    content={profile.displayName}
                                />
                            </ItemContent>
                        </Item>
                    </ItemGroup>
                </GridColumn>
                <GridColumn width={4}>
                    <StatisticGroup>
                        <Statistic
                            label="Followers"
                            value={profile.followerCount || 0}
                        />
                        <Statistic
                            label="Following"
                            value={profile.followingCount || 0}
                        />
                    </StatisticGroup>
                    <Divider />
                    {!isCurrentUser && (
                        <>
                            <Reveal animated="move">
                                <RevealContent
                                    visible
                                    style={{ width: '100%' }}
                                >
                                    <Button
                                        fluid
                                        color="teal"
                                        content={
                                            followingUser
                                                ? 'Following'
                                                : 'Not following'
                                        }
                                    />
                                </RevealContent>
                                <RevealContent hidden style={{ width: '100%' }}>
                                    <Button
                                        basic
                                        fluid
                                        onClick={
                                            followingUser
                                                ? () => handleUnfollowUser()
                                                : () => handleFollowUser()
                                        }
                                        loading={loading}
                                        disabled={loading}
                                        color={followingUser ? 'red' : 'green'}
                                        content={
                                            followingUser
                                                ? 'Unfollow'
                                                : 'Follow'
                                        }
                                    />
                                </RevealContent>
                            </Reveal>
                        </>
                    )}
                </GridColumn>
            </Grid>
        </Segment>
    );
}
