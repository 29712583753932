import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import { getUserProfile } from '../../shared/firestore/firestoreService';
import useFirestoreDoc from '../../shared/firestore/hooks/useFirestoreDoc';
import LoadingComponent from '../../shared/utils/LoadingComponent';
import ProfileContent from './ProfileContent';
import ProfileHeader from './ProfileHeader';
import { listenToSelectedUserProfile } from './store/profileActions';

export default function ProfilePage({ match }) {
    const dispatch = useDispatch();
    const { selectedUserProfile, currentUserProfile } = useSelector(
        (state) => state.profile,
    );
    const { currentUser } = useSelector((state) => state.auth);
    const { loading, error } = useSelector((state) => state.async);
    let profile;

    useFirestoreDoc({
        query: () => getUserProfile(match.params.id),
        data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
        deps: [dispatch, match.params.id],
        shouldExecute: match.params.id !== currentUserProfile.id,
    });

    if (match.params.id === currentUserProfile.id) {
        profile = currentUserProfile;
    } else {
        profile = selectedUserProfile;
    }

    if ((loading && !profile) || (!profile && !error))
        return <LoadingComponent content="Loading profile..." />;

    return (
        <Grid>
            <Grid.Column width={16}>
                <ProfileHeader
                    profile={profile}
                    isCurrentUser={currentUser.uid === profile.id}
                />
                <ProfileContent
                    profile={profile}
                    isCurrentUser={currentUser.uid === profile.id}
                />
            </Grid.Column>
        </Grid>
    );
}
