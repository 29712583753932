import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { verifyAuth } from '../../shared/common/registration/store/authActions';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

export function configureStore() {
    const store = createStore(
        rootReducer(history),
        composeWithDevTools(applyMiddleware(thunk)),
    );

    store.dispatch(verifyAuth());

    return store;
}
