import React, { useState } from 'react';

import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import Button from '@material-ui/core/Button';

import ActivityForm from './ActivityForm';
import RevCard from './card/RevCard';
import { RevCards, ListHeader, ListStyle, Title } from './list_styled';

const RevPage = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { activities } = useSelector((state) => state.bucket);

    return (
        <ListStyle>
            <ListHeader>
                <Button onClick={() => setOpen(true)}>
                    <Icon size="large" name="plus" />
                    Add a new place, activity, or anything
                </Button>
            </ListHeader>
            <RevCards>
                {activities.map((activity, index) => (
                    <RevCard key={index} activity={activity} />
                ))}
            </RevCards>
            <ActivityForm open={open} setOpen={setOpen} />
        </ListStyle>
    );
};

export default RevPage;
