import React, { useEffect, useState } from 'react';

import useFirestoreDoc from '../../../../shared/firestore/hooks/useFirestoreDoc';
import { calculateTotalCost, paidActivies } from '../../service/boardService';
import { listenToItineraries } from '../../service/firestoreService';
import ItineraryAttendeeCostView from './ItineraryAttendeeCostView';

export default function ItineraryCostView({ board }) {
    const [attendees, setAttendees] = useState(null);
    const [paidActivities, setPaidActivities] = useState([]);

    useFirestoreDoc({
        query: () => listenToItineraries(board.id),
        data: (itinerary) => {
            setAttendees(itinerary.attendees);
        },
        deps: [board.id],
    });

    useEffect(() => {
        setPaidActivities(paidActivies(board));
    }, [board]);

    return (
        <div>
            <div>
                <table width="98%">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left' }}>Activity Name</th>
                            <th style={{ textAlign: 'left' }}> Cost </th>
                            <th style={{ textAlign: 'left' }}> Person </th>
                        </tr>
                    </thead>

                    <tbody>
                        {paidActivities &&
                            paidActivities.map((paidActivity, index) => (
                                <tr key={index}>
                                    <td
                                        width="45%"
                                        style={{
                                            borderBottom: '2px solid grey',
                                            borderTop: '2px solid grey',
                                            borderLeft: '2px solid grey',
                                        }}
                                    >
                                        {' '}
                                        {paidActivity.text}{' '}
                                    </td>
                                    <td
                                        width="30%"
                                        style={{
                                            borderTop: '2px solid grey',
                                            borderBottom: '2px solid grey',
                                        }}
                                    >
                                        {paidActivity.cost.toLocaleString(
                                            'en-US',
                                            {
                                                style: 'currency',
                                                currency: 'USD',
                                            },
                                        )}
                                    </td>
                                    <td
                                        width="25%"
                                        style={{
                                            borderTop: '2px solid grey',
                                            borderBottom: '2px solid grey',
                                            borderRight: '2px solid grey',
                                        }}
                                    >
                                        {(
                                            paidActivity.cost /
                                            (paidActivity?.attended?.length ?? board.attendees.length)
                                        ).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <div>
                <b>Total cost</b>: ${calculateTotalCost(board)}
            </div>

            <br />
            <div>
                <b>Individual cost</b>
            </div>
            {attendees != null &&
                attendees.map((card, index) => (
                    <ItineraryAttendeeCostView
                        key={index}
                        card={card}
                        board={board}
                    />
                ))}
        </div>
    );
}
