export function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

// -1 when file not exist
export function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
}

/** used in the chat */
export function createDataTree(dataset) {
    let hashtable = Object.create(null);
    dataset.forEach((a) => (hashtable[a.id] = { ...a, childNodes: [] }));
    let dataTree = [];

    dataset.forEach((a) => {
        if (a.parentId) {
            hashtable[a.parentId].childNodes.push(hashtable[a.id]);
        } else {
            dataTree.push(hashtable[a.id]);
        }
    });

    return dataTree;
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function addOneDay(currentDate, day) {
    return currentDate + day * 24 * 60 * 60 * 1000;
}

export function isToday (time) {
    return new Date(time).toDateString() === new Date().toDateString();
}