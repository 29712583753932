import React from 'react';

import Select from 'react-select';

const options = [
    { label: 'Food', value: 'food' },
    { label: 'Drink', value: 'glass martini' },
    { label: 'Dessert', value: 'birthday cake' },
    { label: 'Activity', value: 'star' },
    { label: 'Sightseeing', value: 'torii gate' },
    { label: 'Picture', value: 'camera' },
    { label: 'Phone', value: 'mobile alternate' },
    { label: 'Binoculars', value: 'binoculars' },
    { label: 'House', value: 'building outline' },
    { label: 'Hotel', value: 'hotel' },
    { label: 'Money', value: 'dollar sign' },
    { label: 'Nature', value: 'tree' },
    { label: 'Hiking', value: 'hiking' },
    { label: 'Plane', value: 'plane' },
    { label: 'Car', value: 'car' },
    { label: 'Train', value: 'train' },
    { label: 'Bus', value: 'bus' },
    { label: 'Ship', value: 'ship' },
    { label: 'Bike', value: 'biking' },
    { label: 'Mountain', value: 'mountain' },
    { label: 'Camp', value: 'campground' },
    { label: 'Hippo', value: 'hippo' },
    { label: 'Dragon', value: 'dragon' },
];

export default function ActivityCategoriesForm(values, setFieldValue) {
    const onChange = (option) => {
        setFieldValue(
            'checked',
            option.map((item) => item.value),
        );
    };

    const getValue = () => {
        if (options && values.checked) {
            return options.filter(
                (option) => values.checked.indexOf(option.value) >= 0,
            );
        } else {
            return [];
        }
    };

    return (
        <Select
            closeMenuOnSelect={false}
            name={'checked'}
            value={getValue()}
            onChange={onChange}
            options={options}
            isMulti
            isOptionDisabled={() => getValue().length >= 2}
        />
    );
}
