import React from 'react';

import { Container, Segment, Header, Button } from 'semantic-ui-react';
import styled from 'styled-components';

const Body = styled.div`
    font-size: 20px;
    line-height: 15px;
    margin-top: 20px;
    font-weight: bold;
    display: inline-block;
`;

const MenuActionButton = styled(Button)`
    border-radius: 0.3rem;
    color: white;
    padding: 0.6rem;
    margin-right: 0.4rem;
    margin-left: 1em;
    min-height: 1em;

    font-size: 18px;
    font-weight: bold;
    align-items: center;

    &:hover {
        background-color: #90acc0;
        cursor: pointer;
    }
`;
const Start = styled.div`
    margin-top: 2em;
`;

const HomePage = ({ history }) => {
    return (
        <Segment inverted textAlign="center" vertical className="masthead">
            <Container>
                <Header
                    as="h1"
                    size="huge"
                    inverted
                    style={{ fontSize: '60px' }}
                >
                    {/*<Image*/}
                    {/*    size="massive"*/}
                    {/*    src="/assets/logo.png"*/}
                    {/*    style={{ marginBottom: 12 }}*/}
                    {/*/>*/}
                    Revney
                </Header>
                <Body>Plan and organize your next trip</Body>
                <Start>
                    <MenuActionButton
                        onClick={() => history.push('/itineraries')}
                    >
                        Get started
                    </MenuActionButton>
                </Start>
            </Container>
        </Segment>
    );
};

export default HomePage;
