import styled from 'styled-components';

export const BackgroundMenu = styled.div`
    display: inline-block;
    overflow-x: hidden;

    width: 340px;
    height: 90%;
    background-color: whitesmoke;
    z-index: 1000;
    transform: ${(props) =>
        props.changeBackground ? 'translateX(0)' : `translateX(100%)`};
    transition: transform 0.3s ease;
    position: absolute;
`;

export const PhotoMenu = styled.div`
    display: inline-block;
    overflow-x: hidden;

    width: 340px;
    height: 90%;
    background-color: whitesmoke;
    z-index: 1000;
    transform: ${(props) =>
        props.changePhoto ? 'translateX(0)' : `translateX(100%)`};
    transition: transform 0.3s ease;
    position: absolute;
`;

export const BackgroundTile = styled.div`
    background-color: ${(props) => props.color || 'blue'};
    height: 10%;
    width: 40%;
    margin: 0.5rem;
    margin-top: 1rem;
    border-radius: 0.8rem;
    display: inline-block;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

export const PhotoCredit = styled.a`
    visibility: hidden;

    background: rgba(0, 0, 0, 0.5);
    border-radius: 0.8rem;
    box-sizing: border-box;
    color: #dfe1e6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
`;

export const PhotoTile = styled.div`
    background-image: ${(props) => `url(` + props.url + `)` || ''};
    background-size: contain;
    background-size: cover;
    height: 10%;
    width: 40%;
    margin: 0.5rem;
    margin-top: 1rem;
    border-radius: 0.8rem;
    display: inline-block;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }

    &:hover ${PhotoCredit} {
        visibility: visible;
    }
`;

export const BackgroundColorMenu = styled.div`
    display: inline-block;
    overflow-x: hidden;

    width: 340px;
    height: 90%;
    background-color: whitesmoke;
    z-index: 1001;
    transform: ${(props) =>
        props.changeBackgroundColor ? 'translateX(0)' : `translateX(100%)`};
    transition: transform 0.3s ease;
    position: absolute;
`;

export const MenuWrapper = styled.div`
    z-index: 999;
    overflow-x: hidden;
    width: 350px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: whitesmoke;
    transform: ${(props) =>
        props.showBoardMenu ? 'translateX(0)' : `translateX(100%)`};
    transition: transform 0.3s ease;
    display: block;
`;

export const Menu = styled.div`
    box-sizing: border-box;
    width: 340px;
    padding: 0px 12px;
    color: #172b4d;
`;

export const MenuItems = styled.div`
    border-bottom: 1px solid #ccd2d7;
    align-items: center;
`;

export const Title = styled.h2`
    padding: 20px 0px 2px 0px;
    font-size: 1.2rem;

    font-weight: 500;
    color: #193345;
    text-align: center;
`;

export const MenuArrow = styled.button`
    border: none;
    font-size: 1.5rem;
    background: none;
    color: #42526e;
    font-weight: bold;

    position: absolute;
    top: 16px;
    transition: 0.25s all;
    left: 2rem;

    &:hover {
        color: #193345;
        cursor: pointer;
    }
`;

export const CloseMenu = styled.button`
    border: none;
    font-size: 1.5rem;
    background: none;
    color: #42526e;
    position: absolute;
    top: 18px;
    right: 12px;

    &:hover {
        color: #193345;
        cursor: pointer;
    }
`;

export const OptionsList = styled.ul`
    text-align: left;
    list-style: none;
    cursor: pointer;
    padding-inline-end: 40px;
`;

export const OptionsItem = styled.li`
    display: flex;
    align-items: center;
    color: #193345;
    font-size: 1rem;
    padding: 0.4rem 0.4rem;
    line-height: 1.2rem;
    border-radius: 3px;
    font-weight: 600;
    &:hover {
        background-color: #6b808c3f;
    }
`;

export const Icon = styled.div`
    color: #193345;
    padding: 0.3rem;
    margin-right: 0.3rem;
`;

export const SearchIcon = styled.span`
    position: absolute;
    left: 10px;
    top: 15px;
    color: #42526e;
`;

export const SearchPhotos = styled.input`
    box-sizing: border-box;
    width: 316px;
    background-color: #fafbfc;
    display: block;
    color: #172b4d;
    box-shadow: inset 0 0 0 2px #dfe1e6;
    margin: 8px 0px;
    padding: 0.6rem 2rem;
    border: none;
    font-size: 14px;
    transition-property: background-color, border-color, box-shadow;
    transition-duration: 85ms;
    transition-timing-function: ease;

    &:focus {
        box-shadow: inset 0 0 0 2px rgb(0, 121, 191);
        background-color: #ffffff;
    }
`;
