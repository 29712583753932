import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { updateBackGroundForBoard } from '../../store/actions';
import unsplash from '../../store/unsplash/unsplash';
import {
    CloseMenu,
    Menu,
    MenuArrow,
    MenuItems,
    MenuWrapper,
    OptionsItem,
    OptionsList,
    PhotoCredit,
    PhotoMenu,
    PhotoTile,
    SearchIcon,
    Title,
} from '../../styled/itineraryMenu_styled';
import ItineraryCostView from './ItineraryCostView';
import ItineraryThemeSearch from './ItineraryThemeSearch';

export default function MoreMenu({
    board,
    showBoardMenu,
    setBoardMenu,
    boardId,
}) {
    const dispatch = useDispatch();
    const [changePhoto, setChangePhoto] = useState(false);
    const [cost, setCost] = useState(false);

    const { photosList } = useSelector((state) => state.theme);

    const selectBackgroundPhoto = (object, photoUrl) => {
        unsplash.photos.downloadPhoto(object);
        dispatch(updateBackGroundForBoard(boardId, photoUrl));
    };

    function togglePhotos() {
        setChangePhoto(!changePhoto);
    }

    function toggleBacklog() {
        setCost(!cost);
    }

    return (
        <MenuWrapper showBoardMenu={showBoardMenu}>
            <Menu>
                <MenuItems>
                    {changePhoto ? (
                        <Title>
                            Photos by{' '}
                            <a href="https://unsplash.com/?utm_source=revney&utm_medium=referral">
                                Unsplash
                            </a>
                        </Title>
                    ) : (
                        <Title>Menu</Title>
                    )}
                    {changePhoto ? (
                        <MenuArrow onClick={togglePhotos}>
                            <Icon name="arrow left" />
                        </MenuArrow>
                    ) : null}
                    {cost ? (
                        <MenuArrow onClick={toggleBacklog}>
                            <Icon name="arrow left" />
                        </MenuArrow>
                    ) : null}

                    <CloseMenu onClick={() => setBoardMenu()}>
                        <Icon name="close" />
                    </CloseMenu>
                </MenuItems>
                <PhotoMenu changePhoto={changePhoto}>
                    <ItineraryThemeSearch />
                    <SearchIcon>
                        <Icon name="search" />
                    </SearchIcon>
                    {photosList.map((object, index) => (
                        <PhotoTile
                            key={index}
                            url={object.urls.thumb}
                            onClick={() =>
                                selectBackgroundPhoto(object, object.urls.full)
                            }
                        >
                            <PhotoCredit
                                href={`https://unsplash.com/@${object.user.username}?utm_source=revney&utm_medium=referral`}
                            >
                                {object.user.name}
                            </PhotoCredit>
                        </PhotoTile>
                    ))}
                </PhotoMenu>
                <PhotoMenu changePhoto={cost}>
                    <ItineraryCostView board={board} />
                </PhotoMenu>
                <MenuItems>
                    <OptionsList>
                        <OptionsItem onClick={togglePhotos}>
                            Change Background
                        </OptionsItem>
                        <OptionsItem onClick={toggleBacklog}>
                            Cost breakdown
                        </OptionsItem>
                    </OptionsList>
                </MenuItems>
            </Menu>
        </MenuWrapper>
    );
}
