import React from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Label, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import CustomTextInput from '../../../../shared/common/formFields/CustomTextInput';
import { addUserToBoard } from '../../store/actions';

export default function InviteUserModal({ boardId, open, setOpen }) {
    const dispatch = useDispatch();

    const validationSchema = Yup.object({
        email: Yup.string().required().email(),
    });

    return (
        <Modal open={open} onClose={() => setOpen(false)} size="mini">
            <Formik
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                onSubmit={async (
                    values,
                    { setSubmitting, setErrors, resetForm },
                ) => {
                    try {
                        dispatch(addUserToBoard(values.email, boardId));
                        resetForm();
                    } catch (error) {
                        toast.error(
                            'Your friend is not signed up yet tell her/him to sign up!',
                        );
                    } finally {
                        setSubmitting(false);
                        setOpen(false);
                    }
                }}
            >
                {({ errors, isSubmitting, isValid, dirty }) => (
                    <Form className="ui form">
                        <CustomTextInput
                            name="email"
                            placeholder="abc@gmail.com"
                            autoComplete="off"
                            autoFocus
                        />
                        {errors.auth && (
                            <Label
                                basic
                                color="red"
                                style={{ marginBottom: 10 }}
                                content={errors.auth}
                            />
                        )}
                        <Button
                            disabled={!isValid || isSubmitting || !dirty}
                            loading={isSubmitting}
                            size="small"
                            positive
                            content="Save"
                            type="submit"
                            style={{ display: 'block' }}
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
