import React from 'react';

export default function ActivityTime({ card }) {
    return (
        <>
            <span
                style={{
                    padding: '0 3px 0 3px',
                    borderRadius: '3px',
                    color: '#3f51b5',
                    border: '1px solid #3f51b5',
                    margin: '0 5px 0 0',
                    verticalAlign: 'middle',
                }}
            >
                {new Date(card.time).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                })}
            </span>
        </>
    );
}
