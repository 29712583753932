import React from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Divider, Label } from 'semantic-ui-react';
import * as Yup from 'yup';

import {
    registerInFirebase,
    saveDataInFirebase,
} from '../../../firestore/firebaseService';
import CustomTextInput from '../../formFields/CustomTextInput';
import ModalWrapper from '../../modals/modalWrapper';
import { closeModal, openModal } from '../../modals/store/modalActions';
import SocialLogin from './SocialLogin';

export default function RegisterForm() {
    const dispatch = useDispatch();

    const containsRevney = (string) => /\w+@revney/.test(string);

    return (
        <ModalWrapper size="mini" header="Register">
            <Formik
                initialValues={{ displayName: '', email: '', password: '' }}
                validationSchema={Yup.object({
                    displayName: Yup.string().required(
                        'Display name is required',
                    ),
                    email: Yup.string()
                        .required()
                        .email('Email address is required')
                        .test(
                            'Should not contain the word revney',
                            'Should not contain the word revney',
                            (value) => !containsRevney(value),
                        ),
                    password: Yup.string().required(),
                    passwordConfirmation: Yup.string().test(
                        'passwords-match',
                        'Passwords must match',
                        function (value) {
                            return this.parent.password === value;
                        },
                    ),
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await registerInFirebase(values);
                        saveDataInFirebase();
                        setSubmitting(false);
                        dispatch(closeModal());
                    } catch (error) {
                        setErrors({ auth: error.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, isValid, dirty, errors }) => (
                    <Form className="ui form">
                        <CustomTextInput
                            name="displayName"
                            placeholder="Display name"
                        />
                        <CustomTextInput
                            name="email"
                            placeholder="Email address"
                        />
                        <CustomTextInput
                            name="password"
                            placeholder="Password"
                            type="password"
                        />
                        <CustomTextInput
                            name="passwordConfirmation"
                            placeholder="Password"
                            type="password"
                        />
                        {errors.auth && (
                            <Label
                                basic
                                color="red"
                                style={{ marginBottom: 10 }}
                                content={errors.auth}
                            />
                        )}
                        <Button
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type="submit"
                            fluid
                            size="large"
                            color="teal"
                            content="Register"
                        />
                        <Divider horizontal>Or</Divider>
                        {/*<SocialLogin />*/}
                        <Button
                            fluid
                            color="teal"
                            content="Login"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: 'LoginForm',
                                    }),
                                )
                            }
                        />
                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    );
}
