import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import './domain/style.css';

import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './domain/App';
import { configureStore, history } from './domain/store/configureStore';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './shared/utils/ScrollToTop';

const store = configureStore();

const rootEl = document.getElementById('root');

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ScrollToTop />
                <App />
            </ConnectedRouter>
        </Provider>,
        rootEl,
    );
}

if (module.hot) {
    module.hot.accept('./domain/App', function () {
        setTimeout(render);
    });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
