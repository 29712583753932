import firebase from '../../../core/config/firebase';

const db = firebase.firestore();

export function listenToItineraries(boardId) {
    return db.collection('itineraries').doc(boardId);
}

export function getUserNameById(userId) {
    return db.collection('users').doc(userId);
}
