import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { openModal } from '../../shared/common/modals/store/modalActions';
import ListDateRangePicker from './ListDateRangePicker';
import RevView from './rev/RevView';
import { decrement, increment } from './store/actions';
import TestMap from './TestMap';
import TestPlaceInput from './TestPlaceInput';
// import { Sheet } from './sheet/Sheet';

const Sandbox = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.test.data);
    const defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33,
        },
        zoom: 11,
    };
    const [location, setLocation] = useState(defaultProps);
    const [target, setTarget] = useState(null);

    const { loading } = useSelector((state) => state.async);

    function handleSetLocation(latLng) {
        setLocation({
            ...location,
            center: {
                lat: latLng.lat,
                lng: latLng.lng,
            },
        });
    }

    const oldSandBoxComponent = (
        <>
            <h1> Testing 123</h1>
            <h3> Test data is: {data}</h3>
            <Button
                name="increment"
                loading={loading && target === 'increment'}
                disabled={loading && target !== 'increment'}
                onClick={(e) => {
                    dispatch(increment(20));
                    setTarget(e.target.name);
                }}
                content="Increment"
                color="green"
            />
            <Button
                name="decrement"
                loading={loading && target === 'decrement'}
                disabled={loading && target !== 'decrement'}
                onClick={(e) => {
                    dispatch(decrement(10));
                    setTarget(e.target.name);
                }}
                content="Decrement"
                color="red"
            />
            <Button
                onClick={() =>
                    dispatch(
                        openModal({
                            modalType: 'TestModal',
                            modalProps: { data },
                        }),
                    )
                }
                content="Open Modal"
                color="teal"
            />
            <div style={{ marginTop: 15 }}>
                <TestPlaceInput setLocation={handleSetLocation} />
                <TestMap location={location} />
            </div>
        </>
    );

    return (
        <>
            {/*<RevView />*/}
            <ListDateRangePicker />
            {/*<Sheet />*/}
        </>
    );
};

export default Sandbox;
