import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { getPhotosList } from '../../store/actions/theme';
import { SearchPhotos } from '../../styled/itineraryMenu_styled';

export default function ItineraryThemeSearch() {
    const [searchTerm, setSearchTerm] = useState('Japan'); // maybe use the itinerary title
    const dispatch = useDispatch();

    // called every time searchTerm is changed
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            //gets executed after 700ms
            dispatch(getPhotosList(searchTerm));
        }, 700);

        // delays delayDebounceFn from being executed by clearing timer
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, dispatch]);

    return (
        <>
            <SearchPhotos
                // placeholder="Photos"
                placeholder="Search for a theme"
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </>
    );
}
