import firebase from '../../../core/config/firebase';

const db = firebase.firestore();

////////////////////////////////////////////////////////////////////////////////
///////////////////////////** photos tab **/////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export async function updateUserProfilePhoto(downloadURL, filename) {
    const user = firebase.auth().currentUser;
    const userDocRef = db.collection('users').doc(user.uid);
    const itineraryDocQuery = db
        .collection('itineraries')
        .where('attendeeIds', 'array-contains', user.uid);

    try {
        const userDoc = await userDocRef.get();
        if (!userDoc.data().photoURL) {
            await db.collection('users').doc(user.uid).update({
                photoURL: downloadURL,
            });

            await user.updateProfile({
                photoURL: downloadURL,
            });

            // Does this work?
            const itineraryQuerySnap = await itineraryDocQuery.get();
            for (let i = 0; i < itineraryQuerySnap.docs.length; i++) {
                let itineraryDoc = itineraryQuerySnap.docs[i];
                await itineraryQuerySnap.docs[i].ref.update({
                    attendees: itineraryDoc
                        .data()
                        .attendees.filter((attendee) => {
                            if (attendee.id === user.uid) {
                                attendee.photoURL = downloadURL;
                            }
                            return attendee;
                        }),
                });
            }
        }

        return await db
            .collection('users')
            .doc(user.uid)
            .collection('photos')
            .add({
                name: filename,
                url: downloadURL,
            });
    } catch (error) {
        throw error;
    }
}

export function getUserPhotos(userUid) {
    return db.collection('users').doc(userUid).collection('photos');
}

export async function setMainPhoto(photo) {
    const user = firebase.auth().currentUser;
    // const today = new Date();
    // const eventDocQuery = db
    //     .collection('events')
    //     .where('attendeeIds', 'array-contains', user.uid)
    //     .where('date', '>=', today);
    const userFollowingRef = db
        .collection('following')
        .doc(user.uid)
        .collection('userFollowing');
    const userFollowersRef = db
        .collection('following')
        .doc(user.uid)
        .collection('userFollowers');
    const itineraryDocQuery = db
        .collection('itineraries')
        .where('attendeeIds', 'array-contains', user.uid);

    const batch = db.batch();

    batch.update(db.collection('users').doc(user.uid), {
        photoURL: photo.url,
    });

    try {
        // const eventsQuerySnap = await eventDocQuery.get();
        // for (let i = 0; i < eventsQuerySnap.docs.length; i++) {
        //     let eventDoc = eventsQuerySnap.docs[i];
        //     if (eventDoc.data().hostUid === user.uid) {
        //         batch.update(eventsQuerySnap.docs[i].ref, {
        //             hostPhotoURL: photo.url,
        //         });
        //     }
        //     batch.update(eventsQuerySnap.docs[i].ref, {
        //         attendees: eventDoc.data().attendees.filter((attendee) => {
        //             if (attendee.id === user.uid) {
        //                 attendee.photoURL = photo.url;
        //             }
        //             return attendee;
        //         }),
        //     });
        // }

        const itineraryQuerySnap = await itineraryDocQuery.get();
        for (let i = 0; i < itineraryQuerySnap.docs.length; i++) {
            let itineraryDoc = itineraryQuerySnap.docs[i];
            batch.update(itineraryQuerySnap.docs[i].ref, {
                attendees: itineraryDoc.data().attendees.filter((attendee) => {
                    if (attendee.id === user.uid) {
                        attendee.photoURL = photo.url;
                    }
                    return attendee;
                }),
            });
        }

        const userFollowingSnap = await userFollowingRef.get();
        userFollowingSnap.docs.forEach((docRef) => {
            let followingDocRef = db
                .collection('following')
                .doc(docRef.id)
                .collection('userFollowers')
                .doc(user.uid);
            batch.update(followingDocRef, {
                photoURL: photo.url,
            });
        });

        const userFollowersSnap = await userFollowersRef.get();
        userFollowersSnap.docs.forEach((docRef) => {
            let followingDocRef = db
                .collection('following')
                .doc(docRef.id)
                .collection('userFollowing')
                .doc(user.uid);
            batch.update(followingDocRef, {
                photoURL: photo.url,
            });
        });

        await firebase.database().ref('users').child(user.uid).update({
            photoURL: photo.url,
        });

        await batch.commit();

        return await user.updateProfile({
            photoURL: photo.url,
        });
    } catch (error) {
        throw error;
    }
}

export function deletePhotoFromCollection(photoId) {
    const userUid = firebase.auth().currentUser.uid;
    return db
        .collection('users')
        .doc(userUid)
        .collection('photos')
        .doc(photoId)
        .delete();
}

////////////////////////////////////////////////////////////////////////////////
///////////////////////////** events tab **/////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

// user going to the events?
export function getUserEventsQuery(activeTab, userUid) {
    let eventsRef = db.collection('events');
    const today = new Date();

    switch (activeTab) {
        case 'past':
            return eventsRef
                .where('attendeeIds', 'array-contains', userUid)
                .where('date', '<=', today)
                .orderBy('date', 'desc');
        case 'host':
            return eventsRef.where('hostUid', '==', userUid).orderBy('date');
        default:
            return eventsRef
                .where('attendeeIds', 'array-contains', userUid)
                .where('date', '>=', today)
                .orderBy('date');
    }
}

////////////////////////////////////////////////////////////////////////////////
///////////////////////////** following follower *//////////////////////////////
////////////////////////////////////////////////////////////////////////////////

// user we clicked on is profile
export async function followUser(profile) {
    const user = firebase.auth().currentUser;
    const batch = db.batch(); // keep data consistent all work or none work.

    try {
        batch.set(
            db
                .collection('following')
                .doc(user.uid)
                .collection('userFollowing')
                .doc(profile.id),
            {
                displayName: profile.displayName,
                photoURL: profile.photoURL,
                uid: profile.id,
            },
        );
        batch.update(db.collection('users').doc(user.uid), {
            followingCount: firebase.firestore.FieldValue.increment(1),
        });

        return await batch.commit();
    } catch (error) {
        throw error;
    }
}

export async function unfollowUser(profile) {
    const user = firebase.auth().currentUser;
    const batch = db.batch();

    try {
        batch.delete(
            db
                .collection('following')
                .doc(user.uid)
                .collection('userFollowing')
                .doc(profile.id),
        );

        batch.update(db.collection('users').doc(user.uid), {
            followingCount: firebase.firestore.FieldValue.increment(-1),
        });

        return await batch.commit();
    } catch (error) {
        throw error;
    }
}

export function getFollowersCollection(profileId) {
    return db
        .collection('following')
        .doc(profileId)
        .collection('userFollowers');
}

export function getFollowingCollection(profileId) {
    return db
        .collection('following')
        .doc(profileId)
        .collection('userFollowing');
}

// does this exist?
export async function getFollowingDoc(profileId) {
    const userUid = firebase.auth().currentUser.uid;

    return await db
        .collection('following')
        .doc(userUid)
        .collection('userFollowing')
        .doc(profileId)
        .get();
}
