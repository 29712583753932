import React from 'react';

import { Formik, Form } from 'formik';
import { Segment, Header, Button, Label, Image } from 'semantic-ui-react';
import * as Yup from 'yup';

import { contactData } from '../../core/config/categoryOptions';
import CustomSelectInput from '../../shared/common/formFields/CustomSelectInput';
import CustomTextArea from '../../shared/common/formFields/CustomTextArea';
import { sendUserFeedBack } from '../../shared/firestore/firestoreService';

export default function ContactPage() {
    const initialFormValue = {
        category: '',
        description: '',
    };

    return (
        <Segment>
            <Header dividing size="large" content="Contact us" />
            <Formik
                initialValues={initialFormValue}
                validationSchema={Yup.object({
                    category: Yup.string().required(
                        'You must provide a category',
                    ),
                    description: Yup.string().required(),
                })}
                onSubmit={async (
                    values,
                    { setSubmitting, setErrors, setStatus },
                ) => {
                    try {
                        await sendUserFeedBack(values);
                    } catch (error) {
                        setErrors({ auth: error.message });
                    } finally {
                        setSubmitting(false);
                        setStatus('sent');
                    }
                }}
            >
                {({ status, errors, isSubmitting, isValid, dirty }) => (
                    <Form className="ui form">
                        {status !== 'sent' && (
                            <>
                                <p>
                                    Let us know what you need or what we can do
                                    better!
                                </p>
                                <CustomSelectInput
                                    name="category"
                                    options={contactData}
                                    placeholder="Select"
                                />
                                <CustomTextArea
                                    rows={3}
                                    name="description"
                                    placeholder="Anything you want to let us know!"
                                />
                                {errors.auth && (
                                    <Label
                                        basic
                                        color="red"
                                        style={{ marginBottom: 10 }}
                                        content={errors.auth}
                                    />
                                )}
                                <Button
                                    disabled={
                                        !isValid ||
                                        isSubmitting ||
                                        !dirty ||
                                        status
                                    }
                                    loading={isSubmitting}
                                    size="large"
                                    positive
                                    content="Send"
                                    type="submit"
                                    style={{ display: 'block' }}
                                />
                            </>
                        )}
                        {status === 'sent' && (
                            <>
                                <h3 className="success">Thank you!</h3>
                                <Image src={`/assets/cat.jpg`} fluid />
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </Segment>
    );
}
