/* global google */
import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Icon, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';

import CustomPlaceInput from '../../../../shared/common/formFields/CustomPlaceInput';
import CustomTextInput from '../../../../shared/common/formFields/CustomTextInput';
import { addCardToBoard } from '../../store/actions';

const buttonTextOpacity = 1;
const buttonTextColor = '#5e6c84';
const buttonTextBackground = '#fff';

const StyledButton = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 8px;
    cursor: pointer;
    border-radius: 0.3rem;
    height: 36px;
    width: 200px;
    padding-left: 8px;
    min-width: 200px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
        Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;

    opacity: ${buttonTextOpacity};
    color: ${buttonTextColor};
    background-color: ${buttonTextBackground};

    &:hover {
        background-color: #e1e2e6;
        transition: 0.3s;
    }
`;

export default function ListButton({ targetLocation, listId }) {
    const dispatch = useDispatch();
    const [formOpen, setFormOpen] = useState(false);

    function openForm() {
        setFormOpen(true);
    }

    function closeForm() {
        setFormOpen(false);
    }

    function handleAddCard(values, { setSubmitting }) {
        dispatch(addCardToBoard(listId, values));
        setSubmitting(false);
        closeForm();
    }

    function renderAddButton() {
        return (
            <StyledButton onClick={openForm}>
                <Icon size="small" name="plus" />
                <p>Add another activity</p>
            </StyledButton>
        );
    }

    function renderForm() {
        const Buttons = styled.div`
            display: inline-block;
        `;

        const validationSchema = Yup.object({
            text: Yup.string().required('Please enter an activity name'),
        });

        return (
            <Segment clearing>
                <Formik
                    enableReinitialize
                    initialValues={{
                        text: '',
                        location: {
                            address: '',
                            latLng: null,
                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleAddCard(values, { setSubmitting });
                    }}
                >
                    {({ isSubmitting, dirty, isValid, values }) => (
                        <Form className="ui form">
                            <CustomTextInput
                                name="text"
                                style={{ width: '200px' }}
                                placeholder="Check in at Disneyland*"
                                autoComplete="off"
                                autoFocus
                            />
                            {targetLocation ? (
                                <CustomPlaceInput
                                    name="location"
                                    style={{ width: '200px' }}
                                    options={{
                                        location: new google.maps.LatLng(
                                            targetLocation.latLng,
                                        ),
                                        radius: 2000,
                                    }}
                                    placeholder="1234 tokyo, Japan"
                                />
                            ) : (
                                <CustomPlaceInput
                                    name="location"
                                    style={{ width: '200px' }}
                                    placeholder="1234 Tokyo, Japan"
                                />
                            )}

                            <Button
                                loading={isSubmitting}
                                disabled={!isValid || !dirty || isSubmitting}
                                size="mini"
                                color="blue"
                                type="submit"
                                floated="right"
                                content="Add"
                            />
                            <Button
                                size="mini"
                                onClick={closeForm}
                                type="submit"
                                floated="right"
                                content="Close"
                            />
                        </Form>
                    )}
                </Formik>
            </Segment>
        );
    }

    return formOpen ? renderForm() : renderAddButton();
}
