import styled from 'styled-components';

export const ListStyle = styled.div`
    border: 1px solid lightgrey;
    background-color: white;
    border-radius: 0.3rem;
    width: 100%;
    height: 100%;
    margin: 8px 0px 0px 8px;
    max-height: 100%;
    position: relative;
    white-space: normal;
`;
export const ListIcons = styled.div`
    position: absolute;
    right: 0;
`;
export const Title = styled.div`
    color: #172b4d;
    font-size: 14px;
    line-height: 15px;
    margin: 8px 15px;
    font-weight: bold;
    opacity: 0.7;
    display: inline-block;
`;
export const Date = styled.p`
    color: #172b4d;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    opacity: 0.7;
`;
export const Location = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: gray;
    font-size: 12px;
    line-height: 15px;
    max-width: 120px;
    text-align: right;
    cursor: pointer;
    border-radius: 0.3rem;

    &:hover {
        background-color: #e1e2e6;
    }
`;
export const HelperText = styled.div`
    display: flex;
    align-items: center;
    color: red;
    font-size: 12px;
    font-weight: bold;
`;
export const Container = styled.div``;
export const RevCards = styled.div`
    min-height: 50px;

    @media only screen and (max-width: 720px) and (min-width: 400px) {
        padding: 0 0 0 12px;
    }
    @media only screen and (max-width: 1024px) and (min-width: 720px) {
        padding: 0 0 0 12px;
    }
    @media only screen and (max-width: 2800px) and (min-width: 1024px) {
        padding: 0 0 0 12px;
    }
    margin-bottom: 20px;
`;
export const ListHeader = styled.div`
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
`;
