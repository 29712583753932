import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { deleteAttachmentFromFirebaseStorage } from '../../../service/firebaseService';
import { updateCardFromBoard } from '../../../store/actions';
import {
    Attachment,
    Attachments,
    AttachmentsPreview,
    AttachmentThumbnail,
} from '../../../styled/activity_styled';
import ActivityAttachmentDropZone from './ActivityAttachmentDropZone';

export default function ActivityAttachmentsForm(
    values,
    canUploadAttachment,
    setFieldValue,
    oldAttachments,
    listId,
) {
    const dispatch = useDispatch();
    const [canUpload, setCanUpload] = useState(false);

    useEffect(() => {
        if (!canUploadAttachment || oldAttachments.length > 2) {
            setCanUpload(false);
        } else {
            setCanUpload(true);
        }
    }, [canUploadAttachment, oldAttachments.length]);

    function handleDelete(e, attachment) {
        deleteAttachmentFromFirebaseStorage(attachment.filename)
            .then(() => {
                const updatedAttachments = oldAttachments.filter(
                    (oldAttachment) =>
                        oldAttachment.filename !== attachment.filename,
                );

                dispatch(
                    updateCardFromBoard(listId, updatedAttachments, values),
                );
            })
            .catch((e) => {
                toast.error('Can not delete this image, maybe is not yours?');
            });

        e.preventDefault();
    }

    return (
        <>
            {canUpload && (
                <>
                    <label>Attachments</label>
                    <ActivityAttachmentDropZone setFieldValue={setFieldValue} />
                </>
            )}
            {oldAttachments && (
                <Attachments>
                    {oldAttachments.map((attachment) => (
                        <AttachmentsPreview key={attachment.attachmentURL}>
                            {attachment.filename.includes('.pdf') ? (
                                <Attachment>
                                    <AttachmentThumbnail
                                        href={attachment.attachmentURL}
                                    >
                                        <PictureAsPdfIcon
                                            style={{ fontSize: '80px' }}
                                        />
                                    </AttachmentThumbnail>
                                </Attachment>
                            ) : (
                                <Attachment>
                                    <AttachmentThumbnail
                                        href={attachment.attachmentURL}
                                        style={{
                                            backgroundImage: `url(${attachment.attachmentURL})`,
                                        }}
                                    />
                                </Attachment>
                            )}
                            <Button
                                fluid
                                name={attachment.attachmentURL}
                                basic
                                color="red"
                                icon="trash"
                                onClick={(e) => {
                                    if (
                                        window.confirm(
                                            "Are you sure about deleting this attachment? Action can't be undone",
                                        )
                                    ) {
                                        handleDelete(e, attachment);
                                    }
                                }}
                            />
                        </AttachmentsPreview>
                    ))}
                </Attachments>
            )}
        </>
    );
}
