import firebase from '../../../../core/config/firebase';
import {
    resetBoard,
    resetBoards,
    resetTheme,
} from '../../../../domain/itineraries/store/actions';
import { listenToCurrentUserProfile } from '../../../../domain/profile/store/profileActions';
import { resetActivities } from '../../../../domain/sandbox/rev/store/bucketActions';
import {
    dataFromSnapshot,
    getUserProfile,
} from '../../../firestore/firestoreService';
import { appLoaded } from '../../async/store/asyncActions';
import { SIGN_IN_USER, SIGN_OUT_USER } from './authConstants';

const auth = firebase.auth();

export function signInUser(user) {
    return {
        type: SIGN_IN_USER,
        payload: user,
    };
}

export function resetPassword(email) {
    auth.sendPasswordResetEmail(email)
        .then(function () {
            // Email sent.
        })
        .catch(function (error) {
            // An error happened.
        });
}

export function verifyAuth() {
    return function (dispatch) {
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch(signInUser(user));
                const profileRef = getUserProfile(user.uid);
                profileRef.onSnapshot((snapshot) => {
                    dispatch(
                        listenToCurrentUserProfile(dataFromSnapshot(snapshot)),
                    );
                    dispatch(appLoaded());
                });
            } else {
                // add any redux reset here until a better solution
                dispatch(signOutUser());
                dispatch(resetActivities());
                dispatch(resetBoard());
                dispatch(resetBoards());
                dispatch(resetTheme());
                dispatch(appLoaded());
            }
        });
    };
}

export function signOutUser() {
    return {
        type: SIGN_OUT_USER,
    };
}
