import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import { Header, Icon } from 'semantic-ui-react';

export default function PhotoWidgetDropZone({ setFiles }) {
    const maxSize = 1048576;

    const dropzoneStyles = {
        border: 'dashed 3px #eee',
        borderRadius: '5%',
        paddingTop: '30px',
        textAlign: 'center',
    };

    const dropzoneActive = {
        border: 'dashed 3px green',
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
        [setFiles],
    );

    const { getRootProps, getInputProps, isDragActive, fileRejections } =
        useDropzone({
            onDrop,
            accept: '.jpeg,.jpg,.png,.gif',
            maxSize,
        });

    const isFileTooLarge =
        fileRejections.length > 0 && fileRejections[0].size > maxSize;

    return (
        <div
            {...getRootProps()}
            style={
                isDragActive
                    ? { ...dropzoneStyles, ...dropzoneActive }
                    : dropzoneStyles
            }
        >
            <input {...getInputProps()} />
            {isFileTooLarge && (
                <div className="text-danger mt-2">File is too large.</div>
            )}
            <Icon name="upload" size="huge" />
            <Header content="Drop image here" />
        </div>
    );
}
