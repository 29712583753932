import React from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Label, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import CustomTextInput from '../../../../shared/common/formFields/CustomTextInput';
import { updateBoardName } from '../../store/actions';

export default function ItineraryTitleModal({ title, boardId, open, setOpen }) {
    const dispatch = useDispatch();

    const initialFormValue = title
        ? {
              title: title,
          }
        : {
              title: '',
          };

    const validationSchema = Yup.object({
        title: Yup.string().required('Please enter an title'),
    });

    return (
        <Modal open={open} onClose={() => setOpen(false)} size="mini">
            <Formik
                initialValues={initialFormValue}
                validationSchema={validationSchema}
                onSubmit={async (
                    values,
                    { setSubmitting, setErrors, resetForm },
                ) => {
                    try {
                        dispatch(updateBoardName(values.title, boardId));
                        resetForm();
                    } catch (error) {
                        setErrors({ auth: error.message });
                    } finally {
                        setSubmitting(false);
                        setOpen(false);
                    }
                }}
            >
                {({ errors, isSubmitting, isValid, dirty }) => (
                    <Form className="ui form">
                        <CustomTextInput
                            name="title"
                            placeholder="New title"
                            autoComplete="off"
                            autoFocus
                        />
                        {errors.auth && (
                            <Label
                                basic
                                color="red"
                                style={{ marginBottom: 10 }}
                                content={errors.auth}
                            />
                        )}
                        <Button
                            disabled={!isValid || isSubmitting || !dirty}
                            loading={isSubmitting}
                            size="small"
                            positive
                            content="Save"
                            type="submit"
                            style={{ display: 'block' }}
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
