import React from 'react';

import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { FormField, Label } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDateInput = ({ label, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);

    return (
        <FormField error={meta.touched && !!meta.error}>
            <label>{label}</label>
            <DatePicker
                {...field}
                {...props}
                onChange={(value) => setFieldValue(field.name, value)}
            />
            {meta.touched && meta.error ? (
                <Label basic color="red">
                    {meta.error}
                </Label>
            ) : null}
        </FormField>
    );
};

export default CustomDateInput;
