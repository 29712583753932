import {
    GET_BOARDS_REQUEST,
    GET_BOARDS_SUCCESS,
    GET_BOARD_NAME_SUCCESS,
    RESET_BOARDS,
} from '../actions';

const initialState = {
    isLoading: true,
    boards: [],
};

export default function boardsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_BOARDS_SUCCESS:
            return { ...state, isLoading: false, boards: action.boards };
        case GET_BOARD_NAME_SUCCESS:
            if (!state.boards) return { ...state };

            const newState = state.boards.map((board) => {
                if (board.boardId === action.boardId) {
                    return { ...board, title: action.name };
                } else {
                    return { ...board };
                }
            });
            return { ...state, boards: newState };

        case GET_BOARDS_REQUEST:
            return { ...state, isLoading: true };

        case RESET_BOARDS:
            return initialState;

        default:
            return state;
    }
}
