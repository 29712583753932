import React, { useEffect, useState } from 'react';

import Select from 'react-select';

export default function ActivityAttendeeForm(values, setFieldValue, attendees) {
    const [loadOptions, setLoadOptions] = useState([]);

    useEffect(() => {
        var options = [{ label: 'Select All', value: 'all' }];
        attendees.forEach((attendee) => {
            options.push({ label: attendee.displayName, value: attendee.id });
        });

        setLoadOptions(options);
    }, [attendees]);

    const getValue = () => {
        if (loadOptions && values.attended) {
            return loadOptions.filter(
                (option) => values.attended.indexOf(option.value) >= 0,
            );
        } else {
            return [];
        }
    };

    const handleChange = (selected) => {
        if (
            selected.length &&
            selected.find((option) => option.value === 'all')
        ) {
            const selectedAll = loadOptions.slice(1);
            var attended = [];
            selectedAll.forEach((selectedItem) => {
                attended.push(Object.values(selectedItem)[1]);
            });

            setFieldValue('attended', attended);
        } else {
            setFieldValue(
                'attended',
                selected.map((item) => item.value),
            );
        }
    };

    return (
        <Select
            onChange={(select) => {
                handleChange(select);
            }}
            allowSelectAll
            value={getValue()}
            options={loadOptions}
            defaultOptions
            isMulti
        />
    );
}
