import React from 'react';

import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';

import CustomDateInput from '../../../../shared/common/formFields/CustomDateInput';
import { updateItineraryDate } from '../../store/actions';

export default function ItineraryDateModal({ date, open, setOpen }) {
    const dispatch = useDispatch();

    const initialFormValue = date
        ? {
              date: Intl.DateTimeFormat('en').format(date),
          }
        : {
              date: new Date(),
          };

    return (
        <Modal open={open} onClose={() => setOpen(false)} size="mini">
            <Formik
                initialValues={initialFormValue}
                onSubmit={async (
                    values,
                    { setSubmitting, setErrors, resetForm },
                ) => {
                    try {
                        dispatch(updateItineraryDate(values.date.getTime()));
                        resetForm();
                    } catch (error) {
                        setErrors({ auth: error.message });
                    } finally {
                        setSubmitting(false);
                        setOpen(false);
                    }
                }}
            >
                {({ isSubmitting, isValid, dirty }) => (
                    <Form className="ui form">
                        <CustomDateInput
                            name="date"
                            placeholderText="Event date"
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy"
                            autoComplete="off"
                        />
                        <Button
                            disabled={!isValid || isSubmitting || !dirty}
                            loading={isSubmitting}
                            size="small"
                            positive
                            content="Save"
                            type="submit"
                            style={{ display: 'block' }}
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
