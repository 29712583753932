/* global google */
import React from 'react';

import { Tooltip } from 'antd';

import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Map';

import CustomPlaceInput from '../../../../shared/common/formFields/CustomPlaceInput';

export default function RevLocationForm() {
    return (
        <>
            <label>Location</label>
            <CustomPlaceInput name="location" placeholder="1234 tokyo, Japan" />
        </>
    );
}
