import React from 'react';
import 'antd/dist/antd.css';

import { Avatar, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { deleteUserFromBoard } from '../../store/actions';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export default function ItineraryAttendee({ attendee, isHost, boardId }) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleDeleteUserFromItinerary(boardId, userId) {
        dispatch(deleteUserFromBoard(boardId, userId));
    }

    return (
        <>
            {isHost && (
                <Tooltip title={attendee.displayName} placement="bottom">
                    <Avatar
                        size={'large'}
                        onClick={handleClick}
                        style={{ cursor: 'pointer' }}
                        src={attendee.photoURL || '/assets/user.png'}
                    />
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            onClick={() =>
                                history.push(`/profile/${attendee.id}`)
                            }
                        >
                            <ListItemText primary="Go to profile" />
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleDeleteUserFromItinerary(
                                    boardId,
                                    attendee.id,
                                );
                            }}
                        >
                            <ListItemText primary="Remove user" />
                        </MenuItem>
                    </StyledMenu>
                </Tooltip>
            )}
            {!isHost && (
                <Tooltip title={attendee.displayName} placement="bottom">
                    <Avatar
                        size={'large'}
                        onClick={() => {
                            history.push(`/profile/${attendee.id}`);
                        }}
                        style={{ cursor: 'pointer' }}
                        src={attendee.photoURL || '/assets/user.png'}
                    />
                </Tooltip>
            )}
        </>
    );
}
