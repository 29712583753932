import React, { useState } from 'react';

import { Tooltip } from 'antd';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { CardOptions, Tile, TileText } from './rev_card_styled';
import RevDetailedFormView from './RevDetailedFormView';

const RevCard = ({ activity }) => {
    const [formOpen, setFormOpen] = useState(false);

    return (
        <>
            <Tile onClick={() => setFormOpen(true)}>
                <TileText>{activity.title}</TileText>
                <CardOptions>
                    <Tooltip
                        title="Delete this"
                        placement="bottom"
                        style={{ float: 'right' }}
                    >
                        <IconButton
                            size={'small'}
                            aria-label="delete"
                            onClick={() => {
                                if (window.confirm('Are you sure')) {
                                    console.log(activity.id);
                                }
                            }}
                            style={{
                                position: 'absolute',
                                top: '5%',
                                left: '75%',
                            }}
                        >
                            <DeleteIcon
                                fontSize={'small'}
                                color={'secondary'}
                            />
                        </IconButton>
                    </Tooltip>
                </CardOptions>
            </Tile>
            <RevDetailedFormView
                activity={activity}
                open={formOpen}
                setOpen={setFormOpen}
            />
        </>
    );
};

export default RevCard;
